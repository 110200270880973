import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Scene } from "@/models/scene";
import { VirtualObject } from "@/models/virtual-object";

export const SET_VIRTUAL_OBJECTS: VirtualObject[] = [
  // STATIC OBJECTS - non-interactable objects (non-products)
  {
    id: "1",
    virtualObjectName: "MainWalls",
    sceneId: "1",
  },
  {
    id: "2",
    virtualObjectName: "KitchenLights",
    sceneId: "3",
  },
  {
    id: "3",
    virtualObjectName: "LoungeSetting",
    sceneId: "1",
  },
  {
    id: "4",
    virtualObjectName: "Kitchen",
    sceneId: "3",
  },
  {
    id: "5",
    virtualObjectName: "Floor",
    sceneId: "1",
  },
  {
    id: "6",
    virtualObjectName: "KitchenIsland",
    sceneId: "3",
  },
  {
    id: "7",
    virtualObjectName: "DiningSetting",
    sceneId: "2",
  },

  // DYNAMIC OBJECTS - interactable objects (products)
  // SCENE 1 - POSITION 1
  {
    id: "8",
    virtualObjectName: "Interactable_1",
    productId: "1",
    sceneId: "1",
    positionId: "1",
  },
  {
    id: "9",
    virtualObjectName: "Interactable_2",
    productId: "2",
    sceneId: "1",
    positionId: "1",
  },
  // SCENE 1 - POSITION 2
  {
    id: "10",
    virtualObjectName: "Interactable_3",
    productId: "3",
    sceneId: "1",
    positionId: "2",
  },
  // SCENE 2 - POSITION 1
  {
    id: "11",
    virtualObjectName: "Interactable_4",
    productId: "4",
    sceneId: "2",
    positionId: "1",
  },
  // SCENE 3 - POSITION 1
  {
    id: "12",
    virtualObjectName: "Interactable_5",
    productId: "5",
    sceneId: "3",
    positionId: "1",
  },
];

export const EXPERIENCE_VIRTUAL_OBJECTS: ExperienceVirtualObject[] = [
  // STATIC OBJECTS - non-interactable objects (non-products)
  {
    id: "1",
    virtualObjectName: "MainWalls",
    sceneId: "1",
  },
  {
    id: "2",
    virtualObjectName: "KitchenLights",
    sceneId: "3",
  },
  {
    id: "3",
    virtualObjectName: "LoungeSetting",
    sceneId: "1",
  },
  {
    id: "4",
    virtualObjectName: "Kitchen",
    sceneId: "3",
  },
  {
    id: "5",
    virtualObjectName: "Floor",
    sceneId: "1",
  },
  {
    id: "6",
    virtualObjectName: "KitchenIsland",
    sceneId: "3",
  },
  {
    id: "7",
    virtualObjectName: "DiningSetting",
    sceneId: "2",
  },

  // DYNAMIC OBJECTS - interactable objects (products)
  // SCENE 1 - POSITION 1
  {
    id: "8",
    virtualObjectName: "Interactable_1",
    productId: "1",
    sceneId: "1",
    positionId: "1",
  },
  // SCENE 1 - POSITION 2
  {
    id: "10",
    virtualObjectName: "Interactable_3",
    productId: "3",
    sceneId: "1",
    positionId: "2",
  },
  // SCENE 2 - POSITION 1
  {
    id: "11",
    virtualObjectName: "Interactable_4",
    productId: "4",
    sceneId: "2",
    positionId: "1",
  },
  // SCENE 3 - POSITION 1
  {
    id: "12",
    virtualObjectName: "Interactable_5",
    productId: "5",
    sceneId: "3",
    positionId: "1",
  },
];

export const demoHouseScenes: Scene[] = [
  {
    id: "1",
    sceneName: "Living Room",
    objectPositions: [
      {
        id: "1",
        positionName: "Side Table",
        selected: "8",
      },
      {
        id: "2",
        positionName: "Another Table",
        selected: "10",
      },
    ],
    videoPositions: [{ id: "1", positionName: "Wall TV", selected: null }],
    // Target position for the camera to look at
    xTargetPosition: -5,
    yTargetPosition: 0.7,
    zTargetPosition: -4,
    // Camera position
    xCameraPosition: -3,
    yCameraPosition: 1.5,
    zCameraPosition: -5,
  },
  {
    id: "2",
    sceneName: "Dining Room",
    objectPositions: [{ id: "1", positionName: "Stove", selected: "11" }],
    // Target position for the camera to look at
    xTargetPosition: -2.2,
    yTargetPosition: 0.7,
    zTargetPosition: -3.5,
    // Camera position
    xCameraPosition: -1,
    yCameraPosition: 1.6,
    zCameraPosition: -1.5,
  },
  {
    id: "3",
    objectPositions: [{ id: "1", positionName: "Sink", selected: "12" }],
    sceneName: "Kitchen",
    // Target position for the camera to look at
    xTargetPosition: 2.4,
    yTargetPosition: 1,
    zTargetPosition: -4.9,
    // Camera position
    xCameraPosition: 1.2,
    yCameraPosition: 1.4,
    zCameraPosition: -2,
  },
];
