/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFFM.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFFM: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFFMF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFFM.glb') as GLTFResult

  const nbproductffmd = useTexture(
    "/textures/NBProductFFMD.webp"
  );
  nbproductffmd.flipY = false;
  nbproductffmd.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductffmmat = new THREE.MeshBasicMaterial({
    map: nbproductffmd,
  });


  return (
    <group {...props} dispose={null}>
      <Float floatIntensity={0.05} speed={7.5} rotationIntensity={0.05} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFFM.geometry} material={nbproductffmmat} position={[2.3, 1.395, -3.6]} rotation={[2.023, 0.035, 1.643]} />
      </Float>
      </group>
  )
}
export const NBProductFFM = () => {
  return (
    
    
    <NBProductFFMF></NBProductFFMF>
  );
};
useGLTF.preload('/glb/NBProductFFM.glb')
