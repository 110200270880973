/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBGlassDoors.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube032: THREE.Mesh
    Cube032_1: THREE.Mesh
    Cube004: THREE.Mesh
    Cube004_1: THREE.Mesh
    Cube006: THREE.Mesh
    Cube006_1: THREE.Mesh
  }
  materials: {

  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>





export function NBGlassDoorsF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBGlassDoors.glb') as GLTFResult

  const nbglassdoorsalpha = useTexture(
  "/textures/NBLogoAlpha.webp"
);
nbglassdoorsalpha.flipY = false;
nbglassdoorsalpha.colorSpace = THREE.SRGBColorSpace;
nbglassdoorsalpha.channel = 1;


const nblogod = useTexture(
  "/textures/NBLogoD.webp"
);
nblogod.flipY = false;
nblogod.colorSpace = THREE.SRGBColorSpace;
nblogod.channel = 1;

const nblogoglassmat = new THREE.MeshStandardMaterial({
  map: nblogod,
  roughness: 0,
  transparent: true, 
  alphaMap: nbglassdoorsalpha,
  metalness: 0.5,
side: THREE.DoubleSide,
});

const nbglassmat = new THREE.MeshStandardMaterial({
  roughness: 0,
  transparent: true, 
  opacity: 0.3,
  metalness: 0.5,
side: THREE.DoubleSide,
});


const nbblackmat = new THREE.MeshStandardMaterial({
  color: 'black',
  roughness: 0.2,
});


  return (
    <group {...props} dispose={null}>
      <group position={[0.91, 0.001, 6.846]} rotation={[Math.PI, -1.134, Math.PI]}>
        <mesh geometry={nodes.Cube032.geometry} material={nbglassmat} />
        <mesh geometry={nodes.Cube032_1.geometry} material={nbblackmat} />
      </group>
      <group position={[-0.91, 0.001, 6.846]} rotation={[-Math.PI, 1.134, -Math.PI]}>
        <mesh geometry={nodes.Cube006.geometry} material={nbglassmat} />
        <mesh geometry={nodes.Cube006_1.geometry} material={nbblackmat} />
      </group>
      <mesh geometry={nodes.Cube004.geometry} material={nbglassmat} />
      <mesh geometry={nodes.Cube004_1.geometry} material={nbblackmat} />
    </group>
  )
}
export const NBGlassDoors = () => {
  return (
    
    
    <NBGlassDoorsF></NBGlassDoorsF>
  );
};
useGLTF.preload('/glb/NBGlassDoors.glb')
