/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFCR.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFCR: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFCRF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFCR.glb') as GLTFResult

  const nbproductfcrd = useTexture(
    "/textures/NBProductFCRD.webp"
  );
  nbproductfcrd.flipY = false;
  nbproductfcrd.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductfcrmat = new THREE.MeshBasicMaterial({
    map: nbproductfcrd,
  });
  
  return (
    <group {...props} dispose={null}>
      <Float floatIntensity={0.05} speed={7} rotationIntensity={0.04} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFCR.geometry} material={nbproductfcrmat} position={[2.3, 1.76, -2.16]} rotation={[2.019, -0.076, 1.415]} />
      </Float>
        </group>
  )
}
export const NBProductFCR = () => {
  return (
    
    
    <NBProductFCRF></NBProductFCRF>
  );
};
useGLTF.preload('/glb/NBProductFCR.glb')
