/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBRecovery.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Cube012: THREE.Mesh;
    Cube012_1: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBRecoveryF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBRecovery.glb") as GLTFResult;

  const nbrecoveryd = useTexture("/textures/NBRecoveryD.webp");
  nbrecoveryd.flipY = false;
  nbrecoveryd.colorSpace = THREE.SRGBColorSpace;
  nbrecoveryd.channel = 1;

  const nbrecoverymat = new THREE.MeshBasicMaterial({
    map: nbrecoveryd,
  });

  const nbrecoveryclearmat = new THREE.MeshStandardMaterial({
    map: nbrecoveryd,
    roughness: 0.1,
    transparent: true,
    opacity: 0.8,
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube012.geometry} material={nbrecoverymat} />
      <mesh geometry={nodes.Cube012_1.geometry} material={nbrecoveryclearmat} />
    </group>
  );
}
export const NBRecovery = () => {
  return <NBRecoveryF></NBRecoveryF>;
};
useGLTF.preload("/glb/NBRecovery.glb");
