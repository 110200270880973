import { NBAmbientAudio } from "../models/NBAmbientAudio";
import { NBBackyard } from "../models/NBBackyard";
import { NBShoe } from "../models/NBFF880Hero";
import { NBFrontEnv } from "../models/NBFrontEnv";
import { NBGiveaway } from "../models/NBGiveaway";
import { NBGlassDoors } from "../models/NBGlassDoors";
import { NBInnerWalls } from "../models/NBInnerWalls";
import { NBLounge } from "../models/NBLounge";

import { NBOutdoor } from "../models/NBOutdoor";
import { NBOuterWalls } from "../models/NBOuterWalls";
import { NBProductFCR } from "../models/NBProductFCR";
import { NBProductFCSCE } from "../models/NBProductFCSCE";
import { NBProductFCSCT } from "../models/NBProductFCSCT";
import { NBProductFF880 } from "../models/NBProductFF880";
import { NBProductFFB } from "../models/NBProductFFB";
import { NBProductFFM } from "../models/NBProductFFM";
import { NBRearEnv } from "../models/NBRearEnv";
import { NBRecovery } from "../models/NBRecovery";

import { NBRefreshment } from "../models/NBRefreshment";
import { NBShelves } from "../models/NBShelves";
import { NBShoebox } from "../models/NBShoeBox";
import { NBSidewalk } from "../models/NBSidewalk";
import { NBVideoPlane } from "../models/NBVideoPlane";

import { WWMNextDoor } from "../models/WWMNextDoor";
import { WWMOuterWalls } from "../models/WWMOuterWalls";

/* 
  A simple mapping between a VirtualObject and a JSX.Element 
  NOTE: the plan in the future is to have a model field in the VirtualObject data model that points to a hosted model file (e.g. GLB) 
*/

export const NBVirtualObjectModelMapping: Record<string, JSX.Element> = {
  // Static Virtual Objects
  "1": NBGlassDoors(),
  "2": NBShoe(),
  "3": NBInnerWalls(),
  "4": NBOuterWalls(),
  "5": NBBackyard(),
  "6": WWMNextDoor(),
  "7": NBShelves(),
  // "8": NBShoesShelf(),
  "9": NBLounge(),
  "10": NBRefreshment(),
  "11": NBRecovery(),
  "12": NBGiveaway(),
  "13": NBShoebox(),
  "14": NBProductFCR(),
  "15": NBProductFCSCE(),
  "16": NBProductFFM(),
  "17": NBProductFFB(),
  "18": NBProductFCSCT(),
  "19": NBProductFF880(),
  "20": NBVideoPlane(),
  "21": NBRearEnv(),
  "22": NBFrontEnv(),
  "23": NBSidewalk(),
  "24": NBAmbientAudio(),
};
