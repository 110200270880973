import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
  count,
  getCountFromServer,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import axios from "axios";
import { Answer } from "@/models/answer";
import { get } from "http";

function generateId() {
  return doc(collection(db, "answers")).id;
}

function getOne(id: string): Observable<Answer> {
  if (!id) return of(undefined);
  const docRef = doc(db, `answers/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(answer: Partial<Answer>, isNew: boolean): Promise<any> {
  try {
    // set answer id to be the same as the question id + user account id.  This ensures that any additional answers to the same question by the same user will overwrite the previous answer.
    answer.id = answer.questionId + answer.userAccountId;

    if (isNew) {
      answer.createdDate = serverTimestamp();
      answer.createdByUserAccountId = auth.currentUser.uid;
    } else {
      answer.updatedDate = serverTimestamp();
      answer.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (answer.deleted == undefined) answer.deleted = false;

    answer = DataTransformationService.convertDatesToFirestoreTimestamp(answer);
    console.log("answer", answer);

    let docRef = doc(db, `answers/${answer.id}`);
    await setDoc(docRef, answer, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(answerId: string): Promise<any> {
  try {
    let docRef = doc(db, `answers/${answerId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function getCountByQuestion(
  tenantId: string,
  brandId: string,
  questionId: string
): Promise<number> {
  try {
    const collectionRef = collection(db, "answers");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("brandId", "==", brandId),
      where("questionId", "==", questionId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    const snapshot = await getCountFromServer(collectionQuery);
    return snapshot.data().count;
  } catch (err) {
    throw err;
  }
}

async function getCountByAnswerOption(
  tenantId: string,
  brandId: string,
  questionId: string,
  answerOptionId: string
): Promise<number> {
  try {
    const collectionRef = collection(db, "answers");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("brandId", "==", brandId),
      where("questionId", "==", questionId),
      where("answerOptionId", "==", answerOptionId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    const snapshot = await getCountFromServer(collectionQuery);
    return snapshot.data().count;
  } catch (err) {
    throw err;
  }
}

function getAllBySurvey(
  tenantId: string,
  brandId: string,
  surveyId: string,
  userAccountId: string
): Observable<Answer[]> {
  const collectionRef = collection(db, "answers");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("surveyId", "==", surveyId),
    where("userAccountId", "==", userAccountId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

// function getAllByQuestions(
//   tenantId: string,
//   brandId: string,
//   userAccountId: string,
//   questionIds: string[]
// ): Observable<Answer[]> {
//   const collectionRef = collection(db, "answers");

//   const queryConstraint: QueryConstraint[] = [
//     where("tenantId", "==", tenantId),
//     where("brandId", "==", brandId),
//     where("userAccountId", "==", userAccountId),
//     where("questionId", "in", questionIds),
//     where("deleted", "==", false),
//   ];

//   const collectionQuery: Query<DocumentData> = query(
//     collectionRef,
//     ...queryConstraint
//   );

//   return collectionData(collectionQuery).pipe(
//     map((collection) => {
//       //convert timestamps to Date
//       collection = collection.map((firestoreDocument) =>
//         DataTransformationService.convertTimestampsToIsoString(
//           firestoreDocument
//         )
//       );
//       return collection;
//     })
//   );
// }

const AnswerService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getCountByAnswerOption,
  getCountByQuestion,
  getAllBySurvey,
  // getAllByQuestions,
};

export default AnswerService;
