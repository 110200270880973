import { createContext, useContext } from "react";

export const AnalyticsContext = createContext<{
  logViewWelcomeScreen: () => void;
  logHostVideoHasStarted: () => void;
  logClickButtonToEnter: () => void;
}>({
  logViewWelcomeScreen: () => {
    /* EMPTY */
  },
  logHostVideoHasStarted: () => {
    /* EMPTY */
  },
  logClickButtonToEnter: () => {
    /* EMPTY */
  },
});

export const useAnalyticsContext = () =>
  useContext<{
    logViewWelcomeScreen: () => void;
    logHostVideoHasStarted: () => void;
    logClickButtonToEnter: () => void;
  }>(AnalyticsContext);
