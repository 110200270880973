import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import { FC, useEffect } from "react";

const ErrorAlert: FC<{
  error: Error | undefined;
  setError: (error: Error | undefined) => void;
}> = ({ error, setError }) => {
  useEffect(() => {
    if (error) console.error(error);
  }, [error]);
  return (
    <AlertDialog open={error ? true : false} defaultOpen={false}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>An error has occured</AlertDialogTitle>
          <AlertDialogDescription>{error?.message}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={() => setError(undefined)}>
            Ok
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ErrorAlert;
