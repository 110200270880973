import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Event } from "../models/event";

function generateId() {
  return doc(collection(db, "events")).id;
}

function getOne(id: string): Observable<Event> {
  const docRef = doc(db, `events/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByGroup(
  tenantId: string,
  brandId: string,
  groupId: string
): Observable<Event[]> {
  const collectionRef = collection(db, "events");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("groupId", "==", groupId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) =>
          (a.createdDate && a.createdDate instanceof Timestamp
            ? a.createdDate.seconds
            : 99999999999) -
          (b.createdDate && b.createdDate instanceof Timestamp
            ? b.createdDate.seconds
            : 99999999999)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(event: Partial<Event>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      event.createdDate = serverTimestamp();
      event.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      event.updatedDate = serverTimestamp();
      event.updatedByUserAccountId = auth.currentUser?.uid;
    }

    if (event.deleted == undefined) event.deleted = false;

    event = DataTransformationService.convertDatesToFirestoreTimestamp(event);

    let docRef = doc(db, `events/${event.id}`);
    await setDoc(docRef, event, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(eventId: string): Promise<any> {
  try {
    let docRef = doc(db, `events/${eventId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const EventService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByGroup,
};

export default EventService;
