import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { CartProductVariant } from "@/models/cart-product-variant";

function generateId() {
  return doc(collection(db, "cartProductVariants")).id;
}

function getOne(id: string): Observable<CartProductVariant> {
  if (!id) return of(undefined);
  const docRef = doc(db, `cartProductVariants/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(
  cartProductVariant: Partial<CartProductVariant>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      cartProductVariant.createdDate = serverTimestamp();
      cartProductVariant.createdByUserAccountId = auth.currentUser.uid;
    } else {
      cartProductVariant.updatedDate = serverTimestamp();
      cartProductVariant.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (cartProductVariant.deleted == undefined)
      cartProductVariant.deleted = false;

    cartProductVariant =
      DataTransformationService.convertDatesToFirestoreTimestamp(
        cartProductVariant
      );

    let docRef = doc(db, `cartProductVariants/${cartProductVariant.id}`);
    await setDoc(docRef, cartProductVariant, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(cartProductVariantId: string): Promise<any> {
  try {
    let docRef = doc(db, `cartProductVariants/${cartProductVariantId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

function getCartProductVariantsByUserAndEvent(
  tenantId?: string,
  userAccountId?: string,
  eventId?: string
): Observable<CartProductVariant[]> {
  if (tenantId && userAccountId && eventId) {
    const collectionRef = collection(db, "cartProductVariants");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("userAccountId", "==", userAccountId),
      where("eventId", "==", eventId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    return collectionData(collectionQuery).pipe(
      map((collection) => {
        //convert timestamps to Date
        collection = collection.map((firestoreDocument) =>
          DataTransformationService.convertTimestampsToIsoString(
            firestoreDocument
          )
        );
        return collection;
      })
    );
  } else {
    return of([]);
  }
}

const CartProductVariantService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getCartProductVariantsByUserAndEvent,
};

export default CartProductVariantService;
