/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBBackyard.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF , useTexture} from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBBackyard: THREE.Mesh
  }
  materials: {}
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBBackyardF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBBackyard.glb') as GLTFResult


  const nbbackyardd = useTexture(
    "/textures/NBBackyardD.webp"
  );
  nbbackyardd.flipY = false;
  nbbackyardd.colorSpace = THREE.SRGBColorSpace;
  nbbackyardd.channel = 1;

  
  const nbbackyardmat = new THREE.MeshBasicMaterial({
    map: nbbackyardd,
  
  });


  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.NBBackyard.geometry} material={nbbackyardmat} />
    </group>
  )
}
export const NBBackyard = () => {
  return (   
    <NBBackyardF></NBBackyardF>
  );
};
useGLTF.preload('/glb/NBBackyard.glb')
