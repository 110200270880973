import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Progress } from "@/components/ui/progress";
import { useAuth } from "@/context/auth.context";
import { useExperienceContext } from "@/context/experience.context";
import { Dialog, DialogTitle } from "@radix-ui/react-dialog";
import { FC, useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { firstValueFrom } from "rxjs";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { AnswerOption, Question } from "@/models/question";
import { Survey } from "@/models/survey";
import QuestionService from "@/services/question.service";
import SurveyService from "@/services/survey.service";
import { DialogDescription } from "../ui/dialog";
import { Card, CardContent, CardTitle } from "../ui/card";
import { ScrollArea } from "../ui/scroll-area";
import AnswerService from "@/services/answer.service";
import { Answer } from "@/models/answer";
import GoogleAnalyticsService from "@/services/googleAnalytics.service";
import UserAccountActionService from "@/services/userAccountAction.service";

const SurveyDrawer: FC<{
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  experienceVirtualObject: ExperienceVirtualObject;
}> = ({ isOpen, setIsOpen, experienceVirtualObject }) => {
  const [snap, setSnap] = useState<number | string | null>(0.7);

  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  const { authUser } = useAuth();
  const { experience, event, placement } = useExperienceContext();

  const [api, setApi] = useState<CarouselApi>();

  const [survey, setSurvey] = useState<Survey>();
  const [questions, setQuestions] = useState<Question[]>();
  const [answers, setAnswers] = useState<Answer[]>();
  const [answerOptionIdDictionary, setAnswerOptionIdDictionary] = useState<any>(
    {}
  ); // { questionId: answerOptionId }
  const [loggedAnalytics, setLoggedAnaltyics] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      if (isOpen && experienceVirtualObject.surveyId) {
        // Get product
        const relatedSurvey: Survey = await firstValueFrom(
          SurveyService.getOne(experienceVirtualObject.surveyId)
        );

        const relatedQuestionsFromServer: Question[] = await firstValueFrom(
          QuestionService.getAllBySurvey(
            relatedSurvey.tenantId,
            relatedSurvey.brandId,
            relatedSurvey.id
          )
        );

        setSurvey(relatedSurvey);
        setQuestions(relatedQuestionsFromServer);
      }

      return;
    };

    getData().catch((err) => {
      alert(err);
      throw err;
    });
  }, [isOpen, experienceVirtualObject]);

  // Create answer options dictionary for to track answers given by user
  useEffect(() => {
    if (survey && questions) {
      const subscription = AnswerService.getAllBySurvey(
        survey.tenantId,
        survey.brandId,
        survey.id,
        authUser.uid
      ).subscribe({
        next: async (answers) => {
          setAnswers(answers);
          //update answer dictionary
          const newAnswerIdDictionary = {};
          questions.forEach((question) => {
            const answer = answers.find(
              (answer) => answer.questionId === question.id
            );
            if (answer) {
              newAnswerIdDictionary[question.id] = answer.answerOptionId;
            } else {
              newAnswerIdDictionary[question.id] = null;
            }
          });
          setAnswerOptionIdDictionary(newAnswerIdDictionary);
        },
        error: (err) => {
          alert(err);
          throw err;
        },
      });

      return () => subscription.unsubscribe();
    }
  }, [survey, questions]);

  /**
   * Log view_item event in analytics
   */
  useEffect(() => {
    const sendData = async () => {
      if (
        isOpen &&
        experience &&
        placement &&
        authUser &&
        placement &&
        experienceVirtualObject &&
        survey &&
        !loggedAnalytics
      ) {
        GoogleAnalyticsService.logGeneralInteraction(
          "view_survey",
          experience,
          placement.id
        );
        await UserAccountActionService.logGeneralInteraction(
          "view_survey",
          experience,
          authUser,
          placement.id,
          experienceVirtualObject.id,
          survey.id
        );
        setLoggedAnaltyics(true);
      }
      return;
    };

    sendData().catch((err) => {
      alert(err);
      throw err;
    });

    if (!isOpen && loggedAnalytics) {
      setLoggedAnaltyics(false);
    }
  }, [
    isOpen,
    experience,
    authUser,
    placement,
    experienceVirtualObject,
    survey,
    loggedAnalytics,
  ]);

  async function saveAnswer(
    question: Question,
    answerOption: AnswerOption,
    isNew?: boolean
  ) {
    try {
      const newAnswer: Answer = {
        tenantId: question.tenantId,
        brandId: question.brandId,
        eventId: event.id,
        experienceId: experience.id,
        placementId: placement.id,
        surveyId: survey.id,
        experienceVirtualObjectId: experienceVirtualObject.id,
        userAccountId: authUser.uid,
        questionId: question.id,
        answerOptionId: answerOption.id,
        answerOptionValue: answerOption.answerOptionValue,
      };
      await AnswerService.saveOne(newAnswer, isNew);
      carouselApi.scrollNext();
    } catch (err) {
      alert(err);
      throw err;
    }
  }

  return (
    <>
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        snapPoints={[0.7, 1]}
        activeSnapPoint={snap}
        setActiveSnapPoint={setSnap}
      >
        <DrawerPortal>
          <DrawerContent className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[95%] mx-[-1px]">
            {/* Close Modal Button */}
            <div className="absolute -top-3 right-3 z-10">
              <Button
                size="icon"
                onClick={() => setIsOpen(false)}
                variant="default"
              >
                <HiOutlineX className="w-6 h-6" />
              </Button>
            </div>

            <DrawerHeader>
              <DialogTitle className="text-xl">
                {survey?.surveyName}
              </DialogTitle>
              <DialogDescription className="text-xs">
                {survey?.surveyDescription}
              </DialogDescription>
            </DrawerHeader>
            {survey && questions && (
              <>
                <Carousel
                  className="w-full h-full"
                  setApi={setCarouselApi}
                  orientation="horizontal"
                >
                  <CarouselContent>
                    {questions.map((question, index) => (
                      <CarouselItem key={index}>
                        <div className="p-2 max-w-full">
                          <Card>
                            <CardContent className="p-6">
                              <CardTitle>{question.questionValue}</CardTitle>
                              <div className="mt-4">
                                {question.answerOptions.map(
                                  (answerOption, index) => (
                                    <Button
                                      key={index}
                                      className="inline-flex m-2"
                                      onClick={() =>
                                        saveAnswer(
                                          question,
                                          answerOption,
                                          answerOptionIdDictionary[question.id]
                                            ? false
                                            : true
                                        )
                                      }
                                      variant={
                                        answerOptionIdDictionary[
                                          question.id
                                        ] === answerOption.id
                                          ? "default"
                                          : "outline"
                                      }
                                    >
                                      {answerOption.answerOptionValue}
                                    </Button>
                                  )
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </CarouselItem>
                    ))}

                    <CarouselItem>
                      <div className="p-2 max-w-full">
                        <Card>
                          <CardContent className="p-6">
                            <CardTitle className="text-center">
                              Thank you!
                            </CardTitle>
                          </CardContent>
                        </Card>
                      </div>
                    </CarouselItem>
                  </CarouselContent>
                  {/* <CarouselPrevious />
                  <CarouselNext /> */}
                </Carousel>
                <div className="h-40" />
              </>
            )}
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </>
  );
};

export default SurveyDrawer;
