import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { Experience } from "@/models/experience";
import { Store } from "@/models/store";
import { Event } from "@/models/event";
import { Placement } from "@/models/placement";
import { TestAccess } from "@/models/test-access";
import { ExperienceProductToBeDeleted } from "@/models/experience-product";
import { ExperienceVideo } from "@/models/experience-video";
import { Set } from "@/models/set";
import { Scene } from "@/models/scene";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { VirtualObject } from "@/models/virtual-object";
import { CartProductVariant } from "@/models/cart-product-variant";
import { ListProductVariant } from "@/models/list-product-variant";
import { Product } from "@/models/product";
import { ProductVariant } from "@/models/product-variant";

export const ExperienceContext = createContext<{
  handleExperienceVirtualObjectTap?: (
    experienceVirtualObjectId: string
  ) => void; // Function to handle the tap event on a virtual object
  setIsMenuDrawerOpen?: Dispatch<SetStateAction<boolean>>; // Function to open/close the menu drawer
  openBrowser?: (
    store: Store,
    experienceVirtualObject: ExperienceVirtualObject,
    product: Product,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean
  ) => void;
  experience: Experience;
  stores: Store[];
  event: Event;
  placements?: Placement[];
  placement?: Placement;
  testAccesses?: TestAccess[];
  cartProductVariants?: CartProductVariant[];
  listProductVariants?: ListProductVariant[];
  experienceVirtualObjects?: ExperienceVirtualObject[]; // Virtual objects that are part of the experience. This creates a many-to-many relationship between virtual objects and an experience
  realExperienceVirtualObjects?: ExperienceVirtualObject[]; // TODO: this is temporary since @rajin-fpd had to override the experience objects with his own. These are populated from what is in the database.
  setExperienceVirtualObjects: Dispatch<
    SetStateAction<ExperienceVirtualObject[]>
  >;
  experienceProducts?: ExperienceProductToBeDeleted[];
  experienceVideos: ExperienceVideo[];
  set: Set; // Set associated with the experience
  setScenes: Dispatch<SetStateAction<Scene[]>>;
  scenes: Scene[]; // Scenes that are available for the set
  setCurrentScene: Dispatch<SetStateAction<Scene>>;
  currentScene: Scene;
  virtualObjects: VirtualObject[]; // Virtual objects that are available for the set. They are associated with an experience using the experienceVirtualObjects property, but this array allows the user to see which objects are available to be selected based on the setId, sceneId and positionId.
  setVirtualObjects: Dispatch<SetStateAction<VirtualObject[]>>;
  canViewExperience?: boolean;
  isMobile?: boolean;
}>(undefined);

export function useExperienceContext() {
  const context = useContext(ExperienceContext);
  return context;
}
