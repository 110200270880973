/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFFB.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFFB: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFFBF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFFB.glb') as GLTFResult

  
  const nbproductffbd = useTexture(
    "/textures/NBProductFFBD.webp"
  );
  nbproductffbd.flipY = false;
  nbproductffbd.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductffbmat = new THREE.MeshBasicMaterial({
    map: nbproductffbd,
  });

  return (
    <group {...props} dispose={null}>
      <Float floatIntensity={0.05} speed={7.4} rotationIntensity={0.04} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFFB.geometry} material={nbproductffbmat} position={[2.31, 1.395, -1.84]} rotation={[2.022, 0.05, 1.673]} />
      </Float>
      </group>
  )
}
export const NBProductFFB = () => {
  return (
    
    
    <NBProductFFBF></NBProductFFBF>
  );
};
useGLTF.preload('/glb/NBProductFFB.glb')
