/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/WWMNextDoor.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube020: THREE.Mesh
  }
  materials: {
    ['Material.001']: THREE.MeshStandardMaterial
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function WWMNextDoorF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/WWMNextDoor.glb') as GLTFResult
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube020.geometry} material={materials['Material.001']} />
    </group>
  )
}
export const WWMNextDoor = () => {
  return (
    
    
    <WWMNextDoorF></WWMNextDoorF>
  );
};
useGLTF.preload('/glb/WWMNextDoor.glb')
