/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBHeroShadow.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useFrame } from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    HeroShadow: THREE.Mesh;
  };
  materials: {};
};

interface NBHeroShadowProps {
  bounce: boolean;
}

export function NBHeroShadowF(
  props: JSX.IntrinsicElements["group"] & NBHeroShadowProps
) {
  const { nodes, materials } = useGLTF("/glb/NBHeroShadow.glb") as GLTFResult;

  const nbheroshadowlm = useTexture("/textures/NBHeroShadowLM.webp");
  nbheroshadowlm.flipY = false;
  nbheroshadowlm.colorSpace = THREE.SRGBColorSpace;

  const nbheroshadowa = useTexture("/textures/NBHeroShadowA.webp");
  nbheroshadowa.flipY = false;
  nbheroshadowa.colorSpace = THREE.SRGBColorSpace;

  const nbheroshadowmat = new THREE.MeshBasicMaterial({
    color: "black",
    lightMap: nbheroshadowlm,
    lightMapIntensity: 7,
    transparent: true,
    alphaMap: nbheroshadowa,
  });

  const meshRef = useRef<THREE.Mesh>(null);

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.006;

      if (props.bounce) {
        meshRef.current.rotation.y += 0.07;
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={meshRef}
        geometry={nodes.HeroShadow.geometry}
        material={nbheroshadowmat}
        position={[-0.177, 0.832, 1.183]}
      />
    </group>
  );
}
export const NBHeroShadow = ({ bounce }: NBHeroShadowProps) => {
  return <NBHeroShadowF bounce={bounce}></NBHeroShadowF>;
};
useGLTF.preload("/glb/NBHeroShadow.glb");
