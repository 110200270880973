import { FieldValue, Timestamp } from "firebase/firestore";
import { format } from "date-fns";

/**
 * Convert Firestore Timestamp types to Date types
 * @param firestoreDocument
 * @returns firestoreDocumentWithTimestampsConvertedToDates
 */
function convertTimestampsToDate(firestoreDocument: any): any {
  const firestoreDocumentWithTimestampsConvertedToDates: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithTimestampsConvertedToDates[key] =
        value instanceof Timestamp ? value.toDate() : value;
    }
  }

  return firestoreDocumentWithTimestampsConvertedToDates;
}

function convertTimestampsToIsoString(firestoreDocument: any): any {
  const firestoreDocumentWithTimestampsConvertedToDates: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithTimestampsConvertedToDates[key] =
        value instanceof Timestamp ? value.toDate().toISOString() : value;
    }
  }

  return firestoreDocumentWithTimestampsConvertedToDates;
}

function convertDatesToFirestoreTimestamp(firestoreDocument: any): any {
  const firestoreDocumentWithDatesConvertedToIsoStrings: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithDatesConvertedToIsoStrings[key] =
        value instanceof Date ? Timestamp.fromDate(value) : value;
    }
  }

  return firestoreDocumentWithDatesConvertedToIsoStrings;
}

function convertDatesToIsoString(firestoreDocument: any): any {
  const firestoreDocumentWithDatesConvertedToIsoStrings: any = {};

  if (firestoreDocument) {
    for (const [key, value] of Object.entries(firestoreDocument)) {
      firestoreDocumentWithDatesConvertedToIsoStrings[key] =
        value instanceof Date ? value.toISOString() : value;
    }
  }

  return firestoreDocumentWithDatesConvertedToIsoStrings;
}

function convertDateToFormat(
  value: string | Date | Timestamp | FieldValue,
  formatString: string
): any {
  if (value instanceof Date) return format(value, formatString);

  if (value instanceof Timestamp) return format(value.toDate(), formatString);

  if (typeof value === "string") return format(value, formatString);

  return null;
}

function convertValueToDate(
  value: string | Date | Timestamp | FieldValue
): any {
  if (value instanceof Date) return value;

  if (value instanceof Timestamp) return value.toDate();

  if (typeof value === "string") return new Date(value);

  return null;
}

const DataTransformationService = {
  convertTimestampsToDate,
  convertTimestampsToIsoString,
  convertDatesToFirestoreTimestamp,
  convertDatesToIsoString,
  convertDateToFormat,
  convertValueToDate,
};

export default DataTransformationService;
