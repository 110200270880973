import SetLoader from "@/components/sets/set-loader";
import { Button } from "@/components/ui/button";
import { useExperienceContext } from "@/context/experience.context";
import { useEffect, useState, type FC } from "react";
import { Vector3 } from "three";
import { ViewerView } from "./viewer/ViewerView";
import { SceneIndicator } from "./viewer/SceneIndicator";
import { InteractionContext } from "./viewer/InteractionContext";
import { useTenantContext } from "@/context/tenant.context";
import { useBrandContext } from "@/context/brand.context";
import { ScenesDictionary } from "@/components/sets/set-dictionary";
import { VideoContext } from "./viewer/NB/VideoContext";
import { Menu } from "lucide-react";
import DesktopPage from "./Desktop";
import { Scene } from "@/models/scene";
import IntroVideo from "@/components/viewer/intro-video";
import WelcomePage from "./Welcome";

const ViewerPage: FC = () => {
  const tenant = useTenantContext();
  const brand = useBrandContext();

  const {
    setIsMenuDrawerOpen,
    handleExperienceVirtualObjectTap,
    currentScene,
    setCurrentScene,
    experienceVirtualObjects,
    experience,
    set,
    canViewExperience,
    isMobile,
  } = useExperienceContext();

  // State for the current camera position
  const [currentCameraPosition, setCurrentCameraPosition] =
    useState<THREE.Vector3>(new Vector3(1, 1, 4));

  // State for the current camera target position (only used intially)
  const [currentTargetPosition, setCurrentTargetPosition] =
    useState<THREE.Vector3>(new Vector3(0, 0, 0));

  const [currentlySelected, setCurrentlySelected] = useState<string | null>(
    null
  );

  /**
   * INTRO VIDEO STATE
   * State to keep track of whether the user has played the intro video or not
   * Uses local storage to persist the value across visits
   */
  const [hasPlayedIntroVideo, setHasPlayedIntroVideo] =
    useState<boolean>(false);
  useEffect(() => {
    if (hasPlayedIntroVideo) {
      localStorage.setItem("hasPlayedIntroVideo", "true");
    }
  }, [hasPlayedIntroVideo]);

  // NB specific states
  const [hasAcceptedPermissions, setHasAcceptedPermissions] =
    useState<boolean>(false);
  const [hasPlayedHostVideo, setHasPlayedHostVideo] = useState<boolean>(false);
  const [hasExperienceLoaded, setHasExperienceLoaded] =
    useState<boolean>(false);
  const [loadedVideo, setLoadedVideo] = useState<HTMLVideoElement | null>(null);
  const [loadedVideoTexture, setLoadedVideoTexture] =
    useState<THREE.VideoTexture | null>(null);

  const [navigatableScenes, setNavigatableScenes] = useState<Scene[]>([]);

  const handleSelection = (objId: string | null) => {
    setCurrentlySelected(objId);
  };

  const scenes = ScenesDictionary[set.setComponentName];

  useEffect(() => {
    setCurrentScene(scenes[0]);
    setNavigatableScenes(scenes.slice(1)); // remove the first scene from the list
  }, []);

  useEffect(() => {
    setCurrentCameraPosition(
      new Vector3(
        currentScene?.xCameraPosition,
        currentScene?.yCameraPosition,
        currentScene?.zCameraPosition
      )
    );

    setCurrentTargetPosition(
      new Vector3(
        currentScene?.xTargetPosition,
        currentScene?.yTargetPosition,
        currentScene?.zTargetPosition
      )
    );
  }, [currentScene]);

  const navigateNextScene = () => {
    const currentIndex = scenes.indexOf(currentScene);
    const nextIndex = (currentIndex + 1) % scenes.length;

    // prevent going back to the host video
    if (nextIndex === 0) {
      return -1;
    }

    handleSelection(null); // reset selection
    setCurrentScene(scenes[nextIndex]);
    return 0;
  };

  const canNavigateNextScene =
    (scenes.indexOf(currentScene) + 1) % scenes.length !== 0;

  const navigatePrevScene = () => {
    const currentIndex = scenes.indexOf(currentScene);
    const prevIndex = (currentIndex - 1 + scenes.length) % scenes.length;

    // prevent going back to the host video
    if (prevIndex === 0) {
      return -1;
    }

    handleSelection(null); // reset selection
    setCurrentScene(scenes[prevIndex]);
    return 0;
  };

  const canNavigatePrevScene =
    (scenes.indexOf(currentScene) - 1 + scenes.length) % scenes.length !== 0;

  // auto-transition from scene 1 to scene 2 after the host video has played
  useEffect(() => {
    if (hasPlayedHostVideo) {
      setCurrentScene(scenes[1]);
    }
  }, [hasPlayedHostVideo]);

  /**
   * Restrict access to the experience if the user is not allowed to view it
   */
  if (canViewExperience === false) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center py-16">
        <h1 className="mb-6 text-2xl font-bold  md:text-5xl">
          Experience coming soon...
        </h1>
      </div>
    );
  }

  /**
   * If the user is on desktop, show the desktop page
   */
  if (isMobile === false && experience.mobileOnly === true) {
    return (
      <DesktopPage
        mobileOnlyExperienceImageUrl={experience.mobileOnlyDesktopImageUrl}
      />
    );
  }

  /**
   * Experience Viewer
   */

  if (experience.introVideoUrl && !hasPlayedIntroVideo) {
    {
      /* Show Intro Video if it exists and has not been played */
    }
    return (
      <IntroVideo
        introVideoUrl={experience.introVideoUrl}
        setHasPlayedIntroVideo={setHasPlayedIntroVideo}
      />
    );
  } else if (!hasAcceptedPermissions) {
    return (
      <WelcomePage
        hostVideoUrl={experience.hostVideoUrl}
        welcomeImageUrl={experience.welcomeImageUrl}
        setHasAcceptedPermissions={setHasAcceptedPermissions}
        setHasPlayedHostVideo={setHasPlayedHostVideo}
        setLoadedVideo={setLoadedVideo}
        setLoadedVideoTexture={setLoadedVideoTexture}
      />
    );
  } else {
    return (
      <div className="viewer-page h-full">
        {hasPlayedHostVideo && (
          <div className="h-full w-full z-[1] absolute p-10 flex flex-col justify-end gap-10 pointer-events-none">
            <div className="items-center justify-between flex flex-row">
              <Button
                className={`${
                  canNavigatePrevScene ? "" : "invisible"
                } pointer-events-auto`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigatePrevScene();
                }}
              >
                {"❮ Prev"}
              </Button>
              <Button
                className="pointer-events-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMenuDrawerOpen(true);
                }}
              >
                <Menu />
              </Button>
              <Button
                className={`${
                  canNavigateNextScene ? "" : "invisible"
                } pointer-events-auto`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigateNextScene();
                }}
              >
                {"Next ❯"}
              </Button>
            </div>
            <SceneIndicator
              selectedSceneIndex={
                currentScene ? scenes.indexOf(currentScene) - 1 : 0
              }
              sceneCount={navigatableScenes.length}
            />
          </div>
        )}
        <SetLoader />
        <VideoContext.Provider
          value={{
            hasPlayedHostVideo,
            hasExperienceLoaded,
            video: loadedVideo,
            videoTexture: loadedVideoTexture,
          }}
        >
          <InteractionContext.Provider
            value={{
              currentSceneId: currentScene?.id,
              interactableProducts: experienceVirtualObjects
                .filter((e) => e.productId && e.sceneId === currentScene?.id)
                .map((e) => e.productId),
              currentlySelected,
              setCurrentlySelected: handleSelection,
            }}
          >
            <ViewerView
              set={set}
              currentScene={currentScene}
              scenes={scenes}
              currentCameraPosition={currentCameraPosition}
              currentTargetPosition={currentTargetPosition}
              experienceVirtualObjects={experienceVirtualObjects}
              experience={experience}
              setHasExperienceLoaded={setHasExperienceLoaded}
              handleExperienceVirtualObjectTap={
                handleExperienceVirtualObjectTap
              }
            />
          </InteractionContext.Provider>
        </VideoContext.Provider>
      </div>
    );
  }

  // TODO: Remove this commented out code once comfortable with the above code.
  //   return (
  //     <>
  //       <div className="viewer-page h-full">
  //         <div className="h-full w-full z-[1] absolute p-10 flex flex-col justify-end gap-10 pointer-events-none">
  //           <div className="items-center justify-between flex flex-row">
  //             <Button
  //               className="pointer-events-auto"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 navigatePrevScene();
  //               }}
  //             >
  //               {"❮ Prev"}
  //             </Button>
  //             <Button
  //               className="pointer-events-auto"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setIsMenuDrawerOpen(true);
  //               }}
  //             >
  //               <Menu />
  //             </Button>
  //             <Button
  //               className="pointer-events-auto"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 navigateNextScene();
  //               }}
  //             >
  //               {"Next ❯"}
  //             </Button>
  //           </div>
  //           <SceneIndicator
  //             selectedSceneIndex={scenes.indexOf(currentScene)}
  //             sceneCount={scenes.length}
  //           />
  //         </div>
  //         <SetLoader />
  //         <InteractionContext.Provider
  //           value={{
  //             currentSceneId: currentScene?.id,
  //             interactableProducts: experienceVirtualObjects
  //               .filter((e) => e.productId && e.sceneId === currentScene?.id)
  //               .map((e) => e.productId),
  //             currentlySelected,
  //             setCurrentlySelected: handleSelection,
  //           }}
  //         >
  //           <ViewerView
  //             set={set}
  //             currentScene={currentScene}
  //             scenes={scenes}
  //             currentCameraPosition={currentCameraPosition}
  //             currentTargetPosition={currentTargetPosition}
  //             experienceVirtualObjects={experienceVirtualObjects}
  //             setHasExperienceLoaded={setHasExperienceLoaded}
  //             experience={experience}
  //             handleExperienceVirtualObjectTap={
  //               handleExperienceVirtualObjectTap
  //             }
  //           />
  //         </InteractionContext.Provider>
  //       </div>
  //     </>
  //   );
};

export default ViewerPage;
