/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBOuterWalls.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF , useTexture} from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBOuterwalls: THREE.Mesh
  }
  materials: {}
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBOuterWallsF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBOuterWalls.glb') as GLTFResult

  const nbouterwallsd = useTexture(
    "/textures/NBOuterWallsD.webp"
  );
  nbouterwallsd.flipY = false;
  nbouterwallsd.colorSpace = THREE.SRGBColorSpace;
  nbouterwallsd.channel = 1;

  const nbouterwallsmat = new THREE.MeshBasicMaterial({
    map: nbouterwallsd,
  });


  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.NBOuterwalls.geometry} material={nbouterwallsmat} />
    </group>
  )
}
export const NBOuterWalls = () => {
  return (
    
    
    <NBOuterWallsF></NBOuterWallsF>
  );
};
useGLTF.preload('/glb/NBOuterWalls.glb')
