/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBShoeBox.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, useTexture, Float } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    NBShoebox: THREE.Mesh;
    NBShoeboxLid: THREE.Mesh;
  };
  materials: {};
};

type ActionName = "NBShoeboxMove";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBShoeboxF(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF(
    "/glb/NBShoeBox.glb"
  ) as GLTFResult;
  const { actions, names } = useAnimations(animations, group);
  useEffect(() => {
    actions[names[0]].play();
  }, []);

  const nbshoeboxd = useTexture("/textures/NBShoeBoxD.webp");
  nbshoeboxd.flipY = false;
  nbshoeboxd.colorSpace = THREE.SRGBColorSpace;
  nbshoeboxd.channel = 0;

  const nbshoeboxmat = new THREE.MeshStandardMaterial({
    map: nbshoeboxd,
    roughness: 0.5,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" position={[0.5, 1.5, -8.5]} rotation={[0.1, 1.2, 0]}>
        <Float
          floatIntensity={1}
          speed={5}
          rotationIntensity={2}
          floatingRange={[-0.3, 0]}
        >
          <mesh
            name="NBShoebox"
            geometry={nodes.NBShoebox.geometry}
            material={nbshoeboxmat}
            scale={[1.2, 1.2, 1.2]}
          >
            <mesh
              name="NBShoeboxLid"
              geometry={nodes.NBShoeboxLid.geometry}
              material={nbshoeboxmat}
              position={[0, 0.075, -0.125]}
            />
          </mesh>
        </Float>
      </group>
    </group>
  );
}
export const NBShoebox = () => {
  return <NBShoeboxF></NBShoeboxF>;
};
useGLTF.preload("/glb/NBShoeBox.glb");
