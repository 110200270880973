/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBGiveaway.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/Viewer/viewer/InteractionContext";
import { useFrame } from "@react-three/fiber";
import { DELAY_MS } from "../shared/NBConstants";
import { set } from "date-fns";

type GLTFResult = GLTF & {
  nodes: {
    ["123003"]: THREE.Mesh;
    ["123003_1"]: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBGiveawayF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBGiveaway.glb") as GLTFResult;
  const { currentSceneId } = useInteractionContext();

  const nbgiveawayd = useTexture("/textures/NBGiveawayD.webp");
  nbgiveawayd.flipY = false;
  nbgiveawayd.colorSpace = THREE.SRGBColorSpace;
  nbgiveawayd.channel = 2;

  const nbgiveawaydarklm = useTexture("/textures/NBGiveawayDarkLM.webp");
  nbgiveawaydarklm.flipY = false;
  nbgiveawaydarklm.colorSpace = THREE.SRGBColorSpace;
  nbgiveawaydarklm.channel = 1;

  const nbgiveawaylightlm = useTexture("/textures/NBGiveawayLightLM.webp");
  nbgiveawaylightlm.flipY = false;
  nbgiveawaylightlm.colorSpace = THREE.SRGBColorSpace;
  nbgiveawaylightlm.channel = 1;

  const nbgiveawaymat = new THREE.MeshBasicMaterial({
    map: nbgiveawayd,
    lightMap: currentSceneId != "1" ? nbgiveawaylightlm : nbgiveawaydarklm,
    lightMapIntensity: 7,
  });

  const nbgiveawaydarkmat = new THREE.MeshBasicMaterial({
    map: nbgiveawayd,
    lightMap: nbgiveawaydarklm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const nbgiveawaylightmat = new THREE.MeshBasicMaterial({
    map: nbgiveawayd,
    lightMap: nbgiveawaylightlm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const nbgiveawaybottlemat = new THREE.MeshStandardMaterial({
    map: nbgiveawayd,
    transparent: true,
    opacity: 0.85,
    lightMap: currentSceneId != "1" ? nbgiveawaylightlm : nbgiveawaydarklm,
    lightMapIntensity: 7,
  });

  const meshRef1 = useRef<THREE.Mesh>(null);
  const meshRef2 = useRef<THREE.Mesh>(null);
  const [fadeProgress, setFadeProgress] = useState(1);
  const [delayPassed, setDelayPassed] = useState(false);

  // manual delay for lightmap transition
  useEffect(() => {
    if (!delayPassed && currentSceneId !== "1") {
      const timer = setTimeout(() => {
        setDelayPassed(true);
        setFadeProgress(1);
      }, DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, [currentSceneId, delayPassed]);

  useFrame((_, delta) => {
    if (meshRef1.current && meshRef2.current) {
      const material1 = meshRef1.current.material as THREE.MeshBasicMaterial;
      const material2 = meshRef2.current.material as THREE.MeshBasicMaterial;

      if (delayPassed) {
        const targetFade = 0;
        setFadeProgress((prev) =>
          THREE.MathUtils.lerp(prev, targetFade, delta * 0.7)
        );

        material2.opacity = 1 - fadeProgress;
        material1.opacity = fadeProgress;
      } else {
        material2.opacity = 0;
        material1.opacity = 1;
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={meshRef1}
        geometry={nodes["123003"].geometry}
        material={nbgiveawaydarkmat}
      />
      <mesh
        ref={meshRef2}
        geometry={nodes["123003"].geometry}
        material={nbgiveawaylightmat}
      />
      <mesh geometry={nodes["123003"].geometry} material={nbgiveawaymat} />
      <mesh
        geometry={nodes["123003_1"].geometry}
        material={nbgiveawaybottlemat}
      />
    </group>
  );
}
export const NBGiveaway = () => {
  return <NBGiveawayF></NBGiveawayF>;
};
useGLTF.preload("/glb/NBGiveaway.glb");
