import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Set } from "../models/set";
import axios from "axios";

function generateId() {
  return doc(collection(db, "sets")).id;
}

function getOne(id: string): Observable<Set> {
  if (!id) return of(undefined);
  const docRef = doc(db, `sets/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(tenantId: string, brandId: string): Observable<Set[]> {
  const collectionRef = collection(db, "sets");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort((a, b) => a.setName.localeCompare(b.setName));
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(set: Partial<Set>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      set.createdDate = serverTimestamp();
      set.createdByUserAccountId = auth.currentUser.uid;
    } else {
      set.updatedDate = serverTimestamp();
      set.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (set.deleted == undefined) set.deleted = false;

    set = DataTransformationService.convertDatesToFirestoreTimestamp(set);

    let docRef = doc(db, `sets/${set.id}`);
    await setDoc(docRef, set, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(setId: string): Promise<any> {
  try {
    let docRef = doc(db, `sets/${setId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

// Uplaods file and returns the file url
async function uploadSetImage(
  brandId: string,
  setId: string,
  file: File
): Promise<string> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.post(
      `brand/${brandId}/sets/r2-signed-url`,
      {
        setId: setId,
        fileName: file.name,
      }
    );

    const signedUrlAndFileUrl: { signedUrl: string; fileUrl: string } =
      apiResponse?.data;
    if (!signedUrlAndFileUrl)
      throw new Error("Error getting signed url during upload process.");

    //upload image to r2
    await axios.put(signedUrlAndFileUrl.signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return signedUrlAndFileUrl.fileUrl;
  } catch (err) {
    throw err;
  }
}

const SetService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
  uploadSetImage,
};

export default SetService;
