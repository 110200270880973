import { Scene } from "@/models/scene";
import { DeviceOrientationControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { MathUtils, Vector3 } from "three";

type Props = {
  currentScene: Scene;
  setHasExperienceLoaded: (hasExperienceLoaded: boolean) => void;
};

const getCameraRotation = (
  cameraPos: THREE.Vector3,
  targetPos: THREE.Vector3
): number => {
  // Default forward direction is along the negative Z-axis
  const defaultForward = new Vector3(0, 0, -1).normalize();

  // Calculate the direction vector from the origin (0, 0, 0) to the target position
  const targetDirection = new Vector3()
    .subVectors(targetPos, cameraPos)
    .normalize();

  // Calculate the angle between the default forward direction and the target direction
  let angleBetween = defaultForward.angleTo(targetDirection);

  // Determine the sign of the angle using the cross product to know if it's to the left or right
  const crossProduct = new Vector3().crossVectors(
    defaultForward,
    targetDirection
  );
  if (crossProduct.y < 0) {
    angleBetween = -angleBetween;
  }

  // Convert the angle from radians to degrees
  let angleInDegrees = MathUtils.radToDeg(angleBetween);

  // Normalize the angle to be within [0, 360) range
  if (angleInDegrees < 0) {
    angleInDegrees += 360;
  }

  return angleInDegrees;
};

/**
 * `CameraRig` handles device orientation controls and the camera positioning
 */
export default function CameraRig({
  currentScene,
  setHasExperienceLoaded,
}: Props) {
  const { camera } = useThree();

  const [initialAlpha, setInitialAlpha] = useState<number | null>(null);
  const [alphaOffset, setAlphaOffset] = useState(0);

  // Initialize the alpha value and set up intial offset
  useEffect(() => {
    const setUpInitialOffset = (event: DeviceOrientationEvent) => {
      // set the initial alpha value
      const initialAlpha = event.alpha ?? 0;
      setInitialAlpha(initialAlpha);
      setHasExperienceLoaded(true);

      // calculate and set the alpha offset
      const targetHeading = getCameraRotation(
        new Vector3(
          currentScene.xCameraPosition,
          currentScene.yCameraPosition,
          currentScene.zCameraPosition
        ),
        new Vector3(
          currentScene.xTargetPosition,
          currentScene.yTargetPosition,
          currentScene.zTargetPosition
        )
      );

      const alpha = initialAlpha;
      const offset = targetHeading - alpha;
      const offsetInRadians = offset * (Math.PI / 180);
      setAlphaOffset(offsetInRadians);

      window.removeEventListener("deviceorientation", setUpInitialOffset);
    };

    window.addEventListener("deviceorientation", setUpInitialOffset);

    return () => {
      window.removeEventListener("deviceorientation", setUpInitialOffset);
    };
  }, []);

  // Update the alpha offset every time the camera position changes
  useEffect(() => {
    const updateOffset = (event: DeviceOrientationEvent) => {
      if (initialAlpha !== undefined && initialAlpha !== null) {
        const targetHeading = getCameraRotation(
          new Vector3(
            currentScene.xCameraPosition,
            currentScene.yCameraPosition,
            currentScene.zCameraPosition
          ),
          new Vector3(
            currentScene.xTargetPosition,
            currentScene.yTargetPosition,
            currentScene.zTargetPosition
          )
        );

        const alpha = event.alpha ?? 0;
        const offset = targetHeading - alpha;
        const offsetInRadians = offset * (Math.PI / 180);
        setAlphaOffset(offsetInRadians);
      }

      window.removeEventListener("deviceorientation", updateOffset);
    };

    window.addEventListener("deviceorientation", updateOffset);

    return () => {
      window.removeEventListener("deviceorientation", updateOffset);
    };
  }, [currentScene]);

  // initially set the camera position
  useEffect(() => {
    const [x, y, z] = [
      currentScene.xCameraPosition,
      currentScene.yCameraPosition,
      currentScene.zCameraPosition,
    ];
    camera.position.set(x, y, z);
  }, []);

  useFrame(({ camera }) => {
    const [x, y, z] = [
      currentScene.xCameraPosition,
      currentScene.yCameraPosition,
      currentScene.zCameraPosition,
    ];
    camera.position.lerp(new Vector3(x, y, z), 0.025);
  });

  return (
    <DeviceOrientationControls
      screenOrientation={0}
      camera={camera}
      alphaOffset={alphaOffset}
    />
  );
}
