import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Scene } from "@/models/scene";
import { VirtualObject } from "@/models/virtual-object";

export const SET_VIRTUAL_OBJECTS: VirtualObject[] = [
  // STATIC OBJECTS - non-interactable objects (non-products)
  {
    id: "1",
    virtualObjectName: "NBGlassDoors",
    sceneId: "1",
  },
  {
    id: "2",
    virtualObjectName: "NBShoe",
    sceneId: "1",
  },
  {
    id: "3",
    virtualObjectName: "NBInnerWalls",
    sceneId: "1",
  },
  {
    id: "4",
    virtualObjectName: "NBOuterWalls",
    sceneId: "1",
  },
  {
    id: "5",
    virtualObjectName: "NBBackyard",
    sceneId: "1",
  },
  {
    id: "6",
    virtualObjectName: "WWMNextDoor",
    sceneId: "1",
  },
  {
    id: "7",
    virtualObjectName: "NBShelves",
    sceneId: "1",
  },
  {
    id: "8",
    virtualObjectName: "NBShoesShelf",
    sceneId: "1",
  },
  {
    id: "9",
    virtualObjectName: "NBLounge",
    sceneId: "1",
  },
  {
    id: "10",
    virtualObjectName: "NBRefreshment",
    sceneId: "1",
  },
  {
    id: "11",
    virtualObjectName: "NBRecovery",
    sceneId: "1",
  },
  {
    id: "12",
    virtualObjectName: "NBGiveaway",
    sceneId: "1",
  },
  {
    id: "13",
    virtualObjectName: "NBShoebox",
    sceneId: "1",
  },
  {
    id: "14",
    virtualObjectName: "NBProductFCR",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "15",
    virtualObjectName: "NBProductFCSCE",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "16",
    virtualObjectName: "NBProductFFM",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "17",
    virtualObjectName: "NBProductFFB",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "18",
    virtualObjectName: "NBProductFCSCT",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "19",
    virtualObjectName: "NBProductFF880",
    positionId: "1",
    sceneId: "1",
  },
  {
    id: "20",
    virtualObjectName: "NBVideoPlane",
    sceneId: "1",
  },
  {
    id: "21",
    virtualObjectName: "NBRearEnv",
    sceneId: "1",
  },  
  {
    id: "22",
    virtualObjectName: "NBFrontEnv",
    sceneId: "1",
  },
  {
    id: "23",
    virtualObjectName: "NBSidewalk",
    sceneId: "1",
  },
  {
    id: "24",
    virtualObjectName: "NBAmbientAudio",
    sceneId: "1",
  },
];

export const EXPERIENCE_VIRTUAL_OBJECTS: ExperienceVirtualObject[] = [
  // STATIC OBJECTS - non-interactable objects (non-products)
  {
    id: "1",
    virtualObjectName: "NBGlassDoors",
    sceneId: "1",
  },
  {
    id: "2",
    virtualObjectName: "NBShoe",
    sceneId: "1",
  },
  {
    id: "3",
    virtualObjectName: "NBInnerWalls",
    sceneId: "1",
  },
  {
    id: "4",
    virtualObjectName: "NBOuterWalls",
    sceneId: "1",
  },
  {
    id: "5",
    virtualObjectName: "NBBackyard",
    sceneId: "1",
  },
  {
    id: "6",
    virtualObjectName: "WWMNextDoor",
    sceneId: "1",
  },
  {
    id: "7",
    virtualObjectName: "NBShelves",
    sceneId: "1",
  },
  {
    id: "8",
    virtualObjectName: "NBShoesShelf",
    sceneId: "1",
  },
  {
    id: "9",
    virtualObjectName: "NBLounge",
    sceneId: "1",
  },
  {
    id: "10",
    virtualObjectName: "NBRefreshBar",
    sceneId: "1",
  },
  {
    id: "11",
    virtualObjectName: "NBRecovery",
    sceneId: "1",
  },
  {
    id: "12",
    virtualObjectName: "NBGiveaway",
    sceneId: "1",
  },
  {
    id: "13",
    virtualObjectId: "HtoRUYDDCSXygoGYiRvm",
    virtualObjectName: "NBShoebox",
    positionId: "7",
    sceneId: "6",
  },
  {
    id: "14",
    virtualObjectId: "G6i03cNt9L6kLeltiFwA",
    virtualObjectName: "NBProductFCR",
    positionId: "1",
    sceneId: "4",
  },
  {
    id: "15",
    virtualObjectId: "C7Pbm0PqFUdb0u6MTtOm",
    virtualObjectName: "NBProductFCSCE",
    positionId: "2",
    sceneId: "4",
  },
  {
    id: "16",
    virtualObjectId: "UpK67g5cU5254IGyhif9",
    virtualObjectName: "NBProductFFM",
    positionId: "3",
    sceneId: "4",
  },
  {
    id: "17",
    virtualObjectId: "Ywy7Z2esumO8uqCQWuTu",
    virtualObjectName: "NBProductFFB",
    positionId: "4",
    sceneId: "4",
  },
  {
    id: "18",
    virtualObjectId: "wbEWcZsFq6SLuIFncLB7",
    virtualObjectName: "NBProductFCSCT",
    positionId: "5",
    sceneId: "4",
  },
  {
    id: "19",
    virtualObjectId: "lbBD8lIlYF04QsQeSSvV",
    virtualObjectName: "NBProductFF880",
    positionId: "6",
    sceneId: "4",
  },
  {
    id: "20",
    virtualObjectName: "NBVideoPlane",
    sceneId: "1",
  },
  {
    id: "21",
    virtualObjectName: "NBRearEnv",
    sceneId: "1",
  },
  {
    id: "22",
    virtualObjectName: "NBFrontEnv",
    sceneId: "1",
  },
  {
    id: "23",
    virtualObjectName: "NBSidewalk",
    sceneId: "1",
  },
  {
    id: "24",
    virtualObjectName: "NBAmbientAudio",
    sceneId: "1",
  },
];

export const nbScenes: Scene[] = [
  {
    id: "1",
    sceneName: "Host Intro",
    objectPositions: [
      {
        id: "1",
        positionName: "Side Table",
        selected: "1-1",
      },
    ],
    videoPositions: [{ id: "1", positionName: "Wall TV", selected: null }],
    // Target position for the camera to look at
    xTargetPosition: -2.3,
    yTargetPosition: 1.2,
    zTargetPosition: 4.1,
    // Camera position
    xCameraPosition: -0.35,
    yCameraPosition: 1.6,
    zCameraPosition: 4.9,
  },
  {
    id: "2",
    sceneName: "Hero Shoe 1",
    objectPositions: [{ id: "1", positionName: "Stove", selected: null }],
    // Target position for the camera to look at
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    // Camera position
    xCameraPosition: 1.2,
    yCameraPosition: 1.35,
    zCameraPosition: 4,
  },
  {
    id: "3",
    objectPositions: [{ id: "1", positionName: "Sink", selected: null }],
    sceneName: "Hero Shoe 2",
    // Target position for the camera to look at
    xTargetPosition: 0,
    yTargetPosition: 1.65,
    zTargetPosition: 1.5,
    // Camera position
    xCameraPosition: -2.2,
    yCameraPosition: 1.35,
    zCameraPosition: 1.1,
  },
  {
    id: "4",
    objectPositions: [
      { id: "1", positionName: "1", selected: "14" },
      { id: "2", positionName: "2", selected: "15" },
      { id: "3", positionName: "3", selected: "16" },
      { id: "4", positionName: "4", selected: "17" },
      { id: "5", positionName: "5", selected: "18" },
      { id: "6", positionName: "6", selected: "19" },
    ],
    sceneName: "Shoe Rack",
    // Target position for the camera to look at
    xTargetPosition: 2.2,
    yTargetPosition: 1.65,
    zTargetPosition: -2.65,
    // Camera position
    xCameraPosition: 0.8,
    yCameraPosition: 1.5,
    zCameraPosition: -2.3,
  },
  {
    id: "5",
    objectPositions: [{ id: "1", positionName: "Sink", selected: null }],
    sceneName: "Hype Real",
    // Target position for the camera to look at
    xTargetPosition: -2,
    yTargetPosition: 1.65,
    zTargetPosition: -5.5,
    // Camera position
    xCameraPosition: 0.1,
    yCameraPosition: 1.4,
    zCameraPosition: -5.1,
  },

  {
    id: "6",
    objectPositions: [
      { id: "1", positionName: "1", selected: "13" },
    ],
    sceneName: "Courtyard",
    // Target position for the camera to look at
    xTargetPosition: -1.5,
    yTargetPosition: 0.7,
    zTargetPosition: -12,
    // Camera position
    xCameraPosition: 0.3,
    yCameraPosition: 1.65,
    zCameraPosition: -7.5,
  },
];
