import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Tenant } from "../models/tenant";

function generateId() {
  return doc(collection(db, "tenants")).id;
}

function getOne(id: string): Observable<Tenant> {
  const docRef = doc(db, `tenants/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(tenant: Partial<Tenant>): Promise<any> {
  try {
    if (!tenant.id) {
      tenant.id = this.generateId();
      tenant.createdDate = serverTimestamp();
      tenant.createdByUserAccountId = auth.currentUser.uid;
    } else {
      tenant.updatedDate = serverTimestamp();
      tenant.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (tenant.deleted == undefined) tenant.deleted = false;

    tenant = DataTransformationService.convertDatesToFirestoreTimestamp(tenant);

    let docRef = doc(db, `tenants/${tenant.id}`);
    await setDoc(docRef, tenant, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const TenantService = {
  generateId,
  getOne,
  saveOne,
};

export default TenantService;
