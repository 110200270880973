import { useAuth } from "@/context/auth.context";
import { useExperienceContext } from "@/context/experience.context";
import { CartProductVariant } from "@/models/cart-product-variant";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { ListProductVariant } from "@/models/list-product-variant";
import { Product } from "@/models/product";
import { ProductVariant } from "@/models/product-variant";
import { Store } from "@/models/store";
import CartProductVariantService from "@/services/cart-product-variant.service";
import GoogleAnalyticsService from "@/services/googleAnalytics.service";
import ListProductVariantService from "@/services/list-product-variant.service";
import ProductVariantService from "@/services/product-variant.service";
import UserAccountActionService from "@/services/userAccountAction.service";
import React, { useContext, useEffect, useRef, useState } from "react";
import { first, firstValueFrom } from "rxjs";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import {
  BookmarkCheck,
  BookmarkMinus,
  BookmarkPlus,
  CircleCheck,
  CircleMinus,
  CirclePlus,
} from "lucide-react";
import ProductService from "@/services/product.service";

interface ContainerProps {
  experienceVirtualObject: ExperienceVirtualObject;
  allowProductRemoval: boolean;
  productData?: Product; //permits the product to be provided to save server calls if the parent component already has the product data
}

const ProductInformation: React.FC<ContainerProps> = ({
  experienceVirtualObject,
  allowProductRemoval,
  productData,
}) => {
  // Context API
  const { authUser, isLoading: authIsLoading } = useAuth();
  const {
    openBrowser,
    event,
    experience,
    placement,
    cartProductVariants,
    listProductVariants,
    stores,
  } = useExperienceContext();

  const [store, setStore] = useState<Store>();
  const [product, setProduct] = useState<Product>();
  const [shoppingCart, setShoppingCart] = useState<any>();
  const [productVariants, setProductVariants] = useState<ProductVariant[]>();

  // Get product data from server if not provided
  useEffect(() => {
    if (productData) setProduct(productData);
    if (!experienceVirtualObject.productId) return;

    firstValueFrom(ProductService.getOne(experienceVirtualObject.productId))
      .then((productFromServer) => {
        setProduct(productFromServer);
      })
      .catch((err) => {
        alert(err);
        throw err;
      });
  }, [experienceVirtualObject, product]);

  // Get product variants and store data
  useEffect(() => {
    const getData = async () => {
      // get product variants
      const relatedProductVariants: ProductVariant[] = await firstValueFrom(
        ProductVariantService.getAllByProduct(
          product.tenantId,
          product.brandId,
          product.id
        )
      );
      setProductVariants(relatedProductVariants);

      // set store that is related to the product
      const relatedStore = stores.find(
        (storeObj) => storeObj.id === product.storeId
      );
      setStore(relatedStore);

      let cartDictionary: any = {};
      for (const cartProductVariant of cartProductVariants) {
        if (
          cartDictionary &&
          cartProductVariant.productVariantId &&
          cartDictionary[String(cartProductVariant.productVariantId)]
        ) {
          cartDictionary[String(cartProductVariant.productVariantId)] =
            cartDictionary[String(cartProductVariant.productVariantId)] + 1;
        } else {
          cartDictionary[String(cartProductVariant.productVariantId)] = 1;
        }
      }

      let newShoppingCart: any = {};
      for (const cartProductVariant of cartProductVariants) {
        newShoppingCart[String(cartProductVariant.productVariantId)] =
          newShoppingCart &&
          newShoppingCart[String(cartProductVariant.productVariantId)]
            ? newShoppingCart[String(cartProductVariant.productVariantId)] + 1
            : 1;
      }

      setShoppingCart(newShoppingCart);

      return;
    };

    if (!product) return;

    getData().catch((err) => {
      alert(err);
      throw err;
    });
  }, [product, cartProductVariants]);

  /**
   * Add To Cart
   * @param productVariant
   */
  const addOneProductVariantToCart = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      // Add product to cart
      const cartProductVariantId: string =
        CartProductVariantService.generateId();

      const cartProductVariant: CartProductVariant = {
        id: cartProductVariantId,
        tenantId: authUser.tenantId,
        brandId: product.brandId,
        eventId: experience?.eventId,
        experienceId: experience?.id,
        placementId: placement.id,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
      };

      await CartProductVariantService.saveOne(cartProductVariant, true);

      // analytics events
      if (store && experience && placement) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_cart",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_cart",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from Cart
   * @param productVariant
   */
  const subtractOneProductVariantFromCart = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const cartProductVariant of cartProductVariants) {
        if (cartProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          // Remove product from cart
          await CartProductVariantService.deleteOne(cartProductVariant.id);

          // analytics events
          if (store && experience && placement) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_cart",
              experience,
              placement.id,
              store,
              experienceVirtualObject,
              product,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_cart",
              experience,
              placement.id,
              store,
              experienceVirtualObject,
              product,
              productVariant,
              authUser
            );
          }

          break;
        }
      }
    } catch (err) {
      alert(err);
      throw err;
    }
  };

  /**
   * Add to List
   * @param productVariant
   */
  const addOneProductVariantToList = async (productVariant: ProductVariant) => {
    try {
      if (!authUser?.uid)
        throw new Error("Invalid user account. Please contact support.");
      // Add product to list
      const listProductVariantId: string =
        ListProductVariantService.generateId();

      const listProductVariant: ListProductVariant = {
        id: listProductVariantId,
        tenantId: authUser.tenantId,
        brandId: product.brandId,
        eventId: experience?.eventId,
        experienceId: experience?.id,
        placementId: placement.id,
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        userAccountId: authUser?.uid,
      };

      await ListProductVariantService.saveOne(listProductVariant, true);

      // analytics events
      if (store && experience && placement) {
        GoogleAnalyticsService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariant
        );
        await UserAccountActionService.logItemInteraction(
          "add_to_wishlist",
          experience,
          placement.id,
          store,
          experienceVirtualObject,
          product,
          productVariant,
          authUser
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  /**
   * Remove from List
   * @param productVariant
   */
  const subtractOneProductVariantFromList = async (
    productVariant: ProductVariant
  ) => {
    try {
      for (const listProductVariant of listProductVariants) {
        if (listProductVariant.productVariantId === productVariant.id) {
          if (!authUser?.uid)
            throw new Error("Invalid user account. Please contact support.");
          // Remove product from list
          await ListProductVariantService.deleteOne(listProductVariant.id);

          // analytics events
          if (store && experience && placement) {
            GoogleAnalyticsService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placement.id,
              store,
              experienceVirtualObject,
              product,
              productVariant
            );
            await UserAccountActionService.logItemInteraction(
              "remove_from_wishlist",
              experience,
              placement.id,
              store,
              experienceVirtualObject,
              product,
              productVariant,
              authUser
            );
          }
          break;
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      {product &&
        store &&
        productVariants &&
        experience &&
        placement &&
        authUser && (
          <div className="grid grid-cols-2 items-center gap-4 pl-2 pr-2">
            <div className="col-span-1">
              {/* No shopping cart, open iFrame when clicking on product image */}
              {(store &&
                store.supportsExperienceShoppingCart === false &&
                productVariants &&
                productVariants[0]?.productVariantUrlInStore && (
                  <img
                    src={product.productImageUrl}
                    className="max-w-full rounded-xl"
                  />
                )) || (
                <img
                  src={product.productImageUrl}
                  className="max-w-full rounded-xl"
                />
              )}
            </div>
            <div className="col-span-1">
              {store?.storeImageUrl && (
                <img
                  src={store?.storeImageUrl}
                  className="w-full max-h-20 object-contain pb-2"
                />
              )}
              {!store?.storeImageUrl && (
                <div className="w-full text-center">{store?.storeName}</div>
              )}

              {productVariants.map(function (productVariant, index) {
                return (
                  <div
                    className="grid grid-cols-2 items-center gap-2"
                    key={index}
                  >
                    {/* Show Product Variant descripiton and price if multiple variants exist */}
                    {productVariants.length > 1 && (
                      <div className="col-span-2">
                        <Label>
                          <h2>{productVariant.productVariantDescription}</h2>
                          <p>
                            ${productVariant.productVariantPrice}{" "}
                            {store?.storeCurrency}
                          </p>
                        </Label>
                      </div>
                    )}

                    {/* Add to cart */}
                    {store && store.supportsExperienceShoppingCart === true && (
                      <div className="col-span-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={async () => {
                            await subtractOneProductVariantFromCart(
                              productVariant
                            );
                          }}
                        >
                          <CircleMinus />
                        </Button>
                        <div>
                          {shoppingCart &&
                          shoppingCart[String(productVariant.id)]
                            ? shoppingCart[String(productVariant.id)]
                            : 0}
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={async () => {
                            await addOneProductVariantToCart(productVariant);
                          }}
                        >
                          <CirclePlus />
                        </Button>
                      </div>
                    )}

                    {/* No shopping cart. Open iFrame */}
                    {store &&
                      store.supportsExperienceShoppingCart === false &&
                      productVariant.productVariantUrlInStore && (
                        <div className="col-span-2">
                          <Button
                            onClick={() => {
                              openBrowser(
                                store,
                                experienceVirtualObject,
                                product,
                                productVariant,
                                productVariant.productVariantUrlInStore,
                                store.storeWebsiteSupportsIFrameEmbed
                              );
                            }}
                            size="sm"
                            className="w-full"
                          >
                            {store.viewProductButtonText &&
                            store.viewProductButtonText.length > 0
                              ? store.viewProductButtonText
                              : "Explore Product"}
                          </Button>
                        </div>
                      )}

                    {/* Shopping List */}
                    {store && store.supportsExperienceShoppingList === true && (
                      <div className="col-span-2">
                        {!listProductVariants.find(
                          (listProductVariant) =>
                            productVariant.id ===
                            listProductVariant.productVariantId
                        ) && (
                          <Button
                            variant="outline"
                            size="sm"
                            className="w-full text-green-700"
                            onClick={async () => {
                              await addOneProductVariantToList(productVariant);
                            }}
                          >
                            Save Item
                            <BookmarkPlus className="ml-2" />
                          </Button>
                        )}
                        {listProductVariants.find(
                          (listProductVariant) =>
                            productVariant.id ===
                            listProductVariant.productVariantId
                        ) && (
                          <>
                            {allowProductRemoval && (
                              <Button
                                variant="outline"
                                size="sm"
                                className="w-full"
                                color="distructive"
                                onClick={async () => {
                                  await subtractOneProductVariantFromList(
                                    productVariant
                                  );
                                }}
                              >
                                Remove Item <BookmarkMinus className="ml-2" />
                              </Button>
                            )}

                            {!allowProductRemoval && (
                              <Button
                                variant="outline"
                                size="sm"
                                className="w-full text-green-700"
                                disabled
                              >
                                Item Saved
                                <BookmarkCheck className="ml-2" />
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
    </>
  );
};

export default ProductInformation;
