/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBShelves.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/Viewer/viewer/InteractionContext";
import { useFrame } from "@react-three/fiber";
import { DELAY_MS } from "../shared/NBConstants";

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBShelvesF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBShelves.glb") as GLTFResult;
  const { currentSceneId } = useInteractionContext();

  const nbshelveslightlm = useTexture("/textures/NBShelvesLightLM.webp");
  nbshelveslightlm.flipY = false;
  nbshelveslightlm.colorSpace = THREE.SRGBColorSpace;

  const nbshelvesdarklm = useTexture("/textures/NBShelvesDarkLM.webp");
  nbshelvesdarklm.flipY = false;
  nbshelvesdarklm.colorSpace = THREE.SRGBColorSpace;

  const nbshelvesmat = new THREE.MeshBasicMaterial({
    color: "#D32330",
    lightMap: currentSceneId != "1" ? nbshelveslightlm : nbshelvesdarklm,
    lightMapIntensity: 7,
  });

  const nbshelvesdarkmat = new THREE.MeshBasicMaterial({
    color: "#D32330",
    lightMap: nbshelvesdarklm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const nbshelveslightmat = new THREE.MeshBasicMaterial({
    color: "#D32330",
    lightMap: nbshelveslightlm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const meshRef1 = useRef<THREE.Mesh>(null);
  const meshRef2 = useRef<THREE.Mesh>(null);
  const [fadeProgress, setFadeProgress] = useState(1);
  const [delayPassed, setDelayPassed] = useState(false);

  // manual delay for lightmap transition
  useEffect(() => {
    if (!delayPassed && currentSceneId !== "1") {
      const timer = setTimeout(() => {
        setDelayPassed(true);
        setFadeProgress(1);
      }, DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, [currentSceneId, delayPassed]);

  useFrame((_, delta) => {
    if (meshRef1.current && meshRef2.current) {
      const material1 = meshRef1.current.material as THREE.MeshBasicMaterial;
      const material2 = meshRef2.current.material as THREE.MeshBasicMaterial;

      if (delayPassed) {
        const targetFade = 0;
        setFadeProgress((prev) =>
          THREE.MathUtils.lerp(prev, targetFade, delta * 0.7)
        );

        material2.opacity = 1 - fadeProgress;
        material1.opacity = fadeProgress;
      } else {
        material2.opacity = 0;
        material1.opacity = 1;
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={meshRef1}
        geometry={nodes.Cube.geometry}
        material={nbshelvesdarkmat}
        position={[2.339, 0.807, -2.739]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        ref={meshRef2}
        geometry={nodes.Cube.geometry}
        material={nbshelveslightmat}
        position={[2.339, 0.807, -2.739]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <mesh
        geometry={nodes.Cube.geometry}
        material={nbshelvesmat}
        position={[2.339, 0.807, -2.739]}
        rotation={[0, Math.PI / 2, 0]}
      />
    </group>
  );
}
export const NBShelves = () => {
  return <NBShelvesF></NBShelvesF>;
};
useGLTF.preload("/glb/NBShelves.glb");
