/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFCSCT.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFCSCT: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFCSCTF(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFCSCT.glb') as GLTFResult

  const nbproductfcsctd = useTexture(
    "/textures/NBProductFCSCTD.webp"
  );
  nbproductfcsctd.flipY = false;
  nbproductfcsctd.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductfcsctmat = new THREE.MeshBasicMaterial({
    map: nbproductfcsctd,
  });


  return (
    <group {...props} dispose={null}>
      <Float floatIntensity={0.05} speed={7.2} rotationIntensity={0.04} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFCSCT.geometry} material={nbproductfcsctmat} position={[2.3, 0.985, -1.95]} rotation={[2.022, 0.05, 1.673]} />
      </Float>
      </group>
  )
}
export const NBProductFCSCT = () => {
  return (
    
    
    <NBProductFCSCTF></NBProductFCSCTF>
  );
};

useGLTF.preload('/glb/NBProductFCSCT.glb')
