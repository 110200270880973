import { analytics } from "../firebase/firebase";
import { logEvent } from "firebase/analytics";
import { ExperienceVirtualObject } from "../models/experience-virtual-object";
import { ProductVariant } from "../models/product-variant";
import { Store } from "../models/store";
import { Experience } from "../models/experience";
import { Product } from "@/models/product";
import { Theme } from "@/models/theme";

function updateThemeVariables(theme?: Theme): void {
  if (!theme) return;

  // Background / Foreground
  if (theme.background)
    document.documentElement.style.setProperty(
      "--background",
      theme.background
    );
  if (theme.foreground)
    document.documentElement.style.setProperty(
      "--foreground",
      theme.foreground
    );

  // Card
  if (theme.card)
    document.documentElement.style.setProperty("--card", theme.card);
  if (theme.cardForeground)
    document.documentElement.style.setProperty(
      "--card-foreground",
      theme.cardForeground
    );

  // Popover
  if (theme.popover)
    document.documentElement.style.setProperty("--popover", theme.popover);
  if (theme.popoverForeground)
    document.documentElement.style.setProperty(
      "--popover-foreground",
      theme.popoverForeground
    );

  // Primary
  if (theme.primary)
    document.documentElement.style.setProperty("--primary", theme.primary);
  if (theme.primaryForeground)
    document.documentElement.style.setProperty(
      "--primary-foreground",
      theme.primaryForeground
    );

  // Secondary
  if (theme.secondary)
    document.documentElement.style.setProperty("--secondary", theme.secondary);
  if (theme.secondaryForeground)
    document.documentElement.style.setProperty(
      "--secondary-foreground",
      theme.secondaryForeground
    );

  // Muted
  if (theme.muted)
    document.documentElement.style.setProperty("--muted", theme.muted);
  if (theme.mutedForeground)
    document.documentElement.style.setProperty(
      "--muted-foreground",
      theme.mutedForeground
    );

  // Accent
  if (theme.accent)
    document.documentElement.style.setProperty("--accent", theme.accent);
  if (theme.accentForeground)
    document.documentElement.style.setProperty(
      "--accent-foreground",
      theme.accentForeground
    );

  // Destructive
  if (theme.destructive)
    document.documentElement.style.setProperty(
      "--destructive",
      theme.destructive
    );
  if (theme.destructiveForeground)
    document.documentElement.style.setProperty(
      "--destructive-foreground",
      theme.destructiveForeground
    );

  // Border
  if (theme.border)
    document.documentElement.style.setProperty("--border", theme.border);

  // Input
  if (theme.input)
    document.documentElement.style.setProperty("--input", theme.input);

  // Ring
  if (theme.ring)
    document.documentElement.style.setProperty("--ring", theme.ring);

  // Radius
  if (theme.radius)
    document.documentElement.style.setProperty("--radius", theme.radius);

  return;
}

const ThemeService = {
  updateThemeVariables,
};

export default ThemeService;
