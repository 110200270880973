import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { Experience } from "../models/experience";
import axios from "axios";

function generateId() {
  return doc(collection(db, "experiences")).id;
}

function getOne(id: string): Observable<Experience | undefined> {
  if (!id) return of(undefined);
  const docRef = doc(db, `experiences/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByEvent(
  tenantId: string,
  brandId: string,
  eventId: string
): Observable<Experience[]> {
  const collectionRef = collection(db, "experiences");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("eventId", "==", eventId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByProduct(
  tenantId: string,
  brandId: string,
  productId: string
): Observable<Experience[]> {
  const collectionRef = collection(db, "experiences");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("productIds", "array-contains", productId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByQuestion(
  tenantId: string,
  brandId: string,
  questionId: string
): Observable<Experience[]> {
  const collectionRef = collection(db, "experiences");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("questionIds", "array-contains", questionId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllByVideo(
  tenantId: string,
  brandId: string,
  videoId: string
): Observable<Experience[]> {
  const collectionRef = collection(db, "experiences");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("videoIds", "array-contains", videoId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

function getAllBySet(
  tenantId: string,
  brandId: string,
  setId: string
): Observable<Experience[]> {
  const collectionRef = collection(db, "experiences");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("setId", "==", setId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort(
        (a, b) => (a.order ? a.order : 0) - (b.order ? b.order : 0)
      );
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(
  experience: Partial<Experience>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      experience.createdDate = serverTimestamp();
      experience.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      experience.updatedDate = serverTimestamp();
      experience.updatedByUserAccountId = auth.currentUser?.uid;
    }

    if (experience.deleted == undefined) experience.deleted = false;

    experience =
      DataTransformationService.convertDatesToFirestoreTimestamp(experience);

    let docRef = doc(db, `experiences/${experience.id}`);
    await setDoc(docRef, experience, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(experienceId: string): Promise<any> {
  try {
    let docRef = doc(db, `experiences/${experienceId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

// Uplaods file and returns the file url
async function uploadFile(
  brandId: string,
  experienceId: string,
  file: File
): Promise<string> {
  try {
    //configure axios client with bearer token
    const token = await auth.currentUser.getIdToken();
    const client = axios.create({
      baseURL: `${functionsUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    //get signed url from firebase function
    const apiResponse = await client.post(
      `brand/${brandId}/experiences/r2-signed-url`,
      {
        experienceId: experienceId,
        fileName: file.name,
      }
    );

    const signedUrlAndFileUrl: { signedUrl: string; fileUrl: string } =
      apiResponse?.data;
    if (!signedUrlAndFileUrl)
      throw new Error("Error getting signed url during upload process.");

    //upload image to r2
    await axios.put(signedUrlAndFileUrl.signedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    return signedUrlAndFileUrl.fileUrl;
  } catch (err) {
    throw err;
  }
}

const ExperienceService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByEvent,
  getAllByProduct,
  getAllByQuestion,
  getAllByVideo,
  getAllBySet,
  uploadFile,
};

export default ExperienceService;
