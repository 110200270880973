import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, functionsUrl } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import axios from "axios";
import { Survey } from "@/models/survey";

function generateId() {
  return doc(collection(db, "surveys")).id;
}

function getOne(id: string): Observable<Survey> {
  if (!id) return of(undefined);
  const docRef = doc(db, `surveys/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

function getAllByBrand(
  tenantId: string,
  brandId: string
): Observable<Survey[]> {
  const collectionRef = collection(db, "surveys");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) => {
      //sort by order
      collection.sort((a, b) => a.surveyName.localeCompare(b.surveyName));
      //convert timestamps to Date
      collection = collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      );
      return collection;
    })
  );
}

async function saveOne(survey: Partial<Survey>, isNew: boolean): Promise<any> {
  try {
    if (isNew) {
      survey.createdDate = serverTimestamp();
      survey.createdByUserAccountId = auth.currentUser.uid;
    } else {
      survey.updatedDate = serverTimestamp();
      survey.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (survey.deleted == undefined) survey.deleted = false;

    survey = DataTransformationService.convertDatesToFirestoreTimestamp(survey);

    let docRef = doc(db, `surveys/${survey.id}`);
    await setDoc(docRef, survey, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(surveyId: string): Promise<any> {
  try {
    let docRef = doc(db, `surveys/${surveyId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

const SurveyService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllByBrand,
};

export default SurveyService;
