import { db } from "../firebase/firebase";
import {
  collection,
  doc,
  DocumentData,
  getCountFromServer,
  query,
  Query,
  QueryConstraint,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { User } from "firebase/auth";
import { UserAccountActions } from "../models/user-account-actions";
import { Experience } from "../models/experience";
import { Store } from "../models/store";
import { ExperienceVirtualObject } from "../models/experience-virtual-object";
import { ProductVariant } from "../models/product-variant";
import { Product } from "@/models/product";

async function logViewExperience(
  experience: Experience,
  placementId: string,
  user: User
): Promise<UserAccountActions> {
  try {
    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: user.uid,
      tenantId: experience.tenantId,
      brandId: experience.brandId,
      experienceId: experience.id,
      placementId: placementId || null,
      isAnonymous: user.isAnonymous,
      userAccountActionType: "view_experience",
      createdDate: serverTimestamp(),
      createdByUserAccountId: user.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    console.log("dbxEvent", "view_experience");

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

async function logGeneralInteraction(
  eventName: UserAccountActions["userAccountActionType"],
  experience: Experience,
  user: User,
  placementId?: string,
  experienceVirtualObjectId?: string,
  surveyId?: string
): Promise<UserAccountActions> {
  try {
    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: user.uid,
      tenantId: experience.tenantId,
      brandId: experience.brandId,
      experienceId: experience.id,
      placementId: placementId || null,
      experienceVirtualObjectId: experienceVirtualObjectId || null,
      surveyId: surveyId || null,
      isAnonymous: user.isAnonymous,
      userAccountActionType: eventName,
      createdDate: serverTimestamp(),
      createdByUserAccountId: user.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    console.log("dbxEvent", eventName);

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

async function logItemInteraction(
  userAccountActionType: UserAccountActions["userAccountActionType"],
  experience: Experience,
  placementId: string,
  store: Store,
  experienceVirtualObject: ExperienceVirtualObject,
  product: Product,
  productVariant: ProductVariant,
  authUser: User
): Promise<UserAccountActions> {
  try {
    console.log("dbxEvent", userAccountActionType);

    const userAccountActionId: string = doc(
      collection(db, "userAccountActions")
    ).id;

    const userAccountAction: UserAccountActions = {
      id: userAccountActionId,
      userAccountId: authUser?.uid,
      tenantId: experience?.tenantId || null,
      brandId: experience?.brandId || null,
      experienceId: experience?.id || null,
      placementId: placementId || null,
      storeId: store?.id || null,
      experienceVirtualObjectId: experienceVirtualObject?.id || null,
      productId: product.id || null,
      productVariantId: productVariant?.id || null,
      userAccountActionType: userAccountActionType,
      isAnonymous: authUser?.isAnonymous,
      createdDate: serverTimestamp(),
      createdByUserAccountId: authUser?.uid,
      deleted: false,
    };

    // save user account action
    await setDoc(
      doc(db, `userAccountActions/${userAccountActionId}`),
      userAccountAction
    );

    return userAccountAction;
  } catch (err) {
    throw err;
  }
}

async function getCountByUserAccountActionType(
  tenantId: string,
  brandId: string,
  experienceId: string,
  userAccountActionType: UserAccountActions["userAccountActionType"]
): Promise<number> {
  try {
    const collectionRef = collection(db, "userAccountActions");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("brandId", "==", brandId),
      where("experienceId", "==", experienceId),
      where("userAccountActionType", "==", userAccountActionType),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    const snapshot = await getCountFromServer(collectionQuery);
    return snapshot.data().count;
  } catch (err) {
    throw err;
  }
}

const UserAccountActionService = {
  logViewExperience,
  logGeneralInteraction,
  logItemInteraction,
  getCountByUserAccountActionType,
};

export default UserAccountActionService;
