/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBVideoPlane.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useVideoContext } from "@/pages/Viewer/viewer/NB/VideoContext";

type GLTFResult = GLTF & {
  nodes: {
    NBVideoPlane: THREE.Mesh;
  };
  materials: {
    Material: THREE.MeshStandardMaterial;
  };
};

export function NBVideoPlaneF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBVideoPlane.glb") as GLTFResult;
  const { video, videoTexture, hasExperienceLoaded, hasPlayedHostVideo } =
    useVideoContext();

  useEffect(() => {
    if (hasExperienceLoaded && video && !hasPlayedHostVideo) {
      video.play().catch((e) => {
        console.log("error with video playback:", e);
      });

      if (videoTexture) {
        videoTexture.flipY = false;
        videoTexture.colorSpace = THREE.SRGBColorSpace;
      }
    }
  }, [hasExperienceLoaded]);

  const NBHostMaskA = useTexture("/videos/NBHostMaskA.webp");
  NBHostMaskA.flipY = false;
  NBHostMaskA.colorSpace = THREE.SRGBColorSpace;

  return (
    hasExperienceLoaded &&
    !hasPlayedHostVideo && (
      <group {...props} dispose={null}>
        <mesh
          geometry={nodes.NBVideoPlane.geometry}
          position={[-1.698, 1.4, 4.42]}
        >
          <meshBasicMaterial
            map={videoTexture}
            transparent
            alphaMap={NBHostMaskA}
          ></meshBasicMaterial>
        </mesh>
      </group>
    )
  );
}
export const NBVideoPlane = () => {
  return <NBVideoPlaneF></NBVideoPlaneF>;
};
useGLTF.preload("/glb/NBVideoPlane.glb");
