import {
  DocumentData,
  Query,
  QueryConstraint,
  Timestamp,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map, of } from "rxjs";
import DataTransformationService from "./data-transformation.service";
import { ListProductVariant } from "@/models/list-product-variant";

function generateId() {
  return doc(collection(db, "listProductVariants")).id;
}

function getOne(id: string): Observable<ListProductVariant> {
  if (!id) return of(undefined);
  const docRef = doc(db, `listProductVariants/${id}`);
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(
  listProductVariant: Partial<ListProductVariant>,
  isNew: boolean
): Promise<any> {
  try {
    if (isNew) {
      listProductVariant.createdDate = serverTimestamp();
      listProductVariant.createdByUserAccountId = auth.currentUser.uid;
    } else {
      listProductVariant.updatedDate = serverTimestamp();
      listProductVariant.updatedByUserAccountId = auth.currentUser.uid;
    }

    if (listProductVariant.deleted == undefined)
      listProductVariant.deleted = false;

    listProductVariant =
      DataTransformationService.convertDatesToFirestoreTimestamp(
        listProductVariant
      );

    let docRef = doc(db, `listProductVariants/${listProductVariant.id}`);
    await setDoc(docRef, listProductVariant, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(listProductVariantId: string): Promise<any> {
  try {
    let docRef = doc(db, `listProductVariants/${listProductVariantId}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
  } catch (err) {
    throw err;
  }
}

function getListProductVariantsByUserAndEvent(
  tenantId?: string,
  userAccountId?: string,
  eventId?: string
): Observable<ListProductVariant[]> {
  if (tenantId && userAccountId && eventId) {
    const collectionRef = collection(db, "listProductVariants");

    const queryConstraint: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
      where("userAccountId", "==", userAccountId),
      where("eventId", "==", eventId),
      where("deleted", "==", false),
    ];

    const collectionQuery: Query<DocumentData> = query(
      collectionRef,
      ...queryConstraint
    );

    return collectionData(collectionQuery).pipe(
      map((collection) => {
        //convert timestamps to Date
        collection = collection.map((firestoreDocument) =>
          DataTransformationService.convertTimestampsToIsoString(
            firestoreDocument
          )
        );
        return collection;
      })
    );
  } else {
    return of([]);
  }
}

const ListProductVariantService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getListProductVariantsByUserAndEvent,
};

export default ListProductVariantService;
