/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFF880.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFF880: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFF880F(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFF880.glb') as GLTFResult

  const nbproductff880d = useTexture(
    "/textures/NBProductFF880D.webp"
  );
  nbproductff880d.flipY = false;
  nbproductff880d.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductff880mat = new THREE.MeshBasicMaterial({
    map: nbproductff880d,
  });


  return (
    <group {...props} dispose={null}>
          <Float floatIntensity={0.05} speed={7.3} rotationIntensity={0.04} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFF880.geometry} material={nbproductff880mat} position={[2.309, 1.766, -3.28]} rotation={[2.077, 0.106, 1.171]} />
      </Float>
      </group>
  )
}
export const NBProductFF880 = () => {
  return (
    
    
    <NBProductFF880F></NBProductFF880F>
  );
};
useGLTF.preload('/glb/NBProductFF880.glb')
