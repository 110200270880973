/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBSidewalk.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube009: THREE.Mesh
    Cube009_1: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBSidewalkF(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBSidewalk.glb') as GLTFResult

  const nbsidewalkd = useTexture("/textures/NBSidewalkD.webp");
  nbsidewalkd.flipY = false;
  nbsidewalkd.colorSpace = THREE.SRGBColorSpace;
  nbsidewalkd.channel = 1;
  nbsidewalkd.wrapS = THREE.RepeatWrapping;
  nbsidewalkd.wrapT = THREE.RepeatWrapping;

  const nbsidewalklm = useTexture("/textures/NBSidewalkLM.webp");
  nbsidewalklm.flipY = false;
  nbsidewalklm.colorSpace = THREE.SRGBColorSpace;
  


  const nbsidewalkmat = new THREE.MeshBasicMaterial({
    map: nbsidewalkd,
    lightMap: nbsidewalklm,
    lightMapIntensity: 7,
  });

  const nbfrontstepd = useTexture("/textures/NBFrontStepD.webp");
  nbfrontstepd.flipY = false;
  nbfrontstepd.colorSpace = THREE.SRGBColorSpace;
  nbfrontstepd.channel = 1;
  nbfrontstepd.wrapS = THREE.RepeatWrapping;
  nbfrontstepd.wrapT = THREE.RepeatWrapping;

  const nbfrontstepmat = new THREE.MeshBasicMaterial({
    map: nbsidewalkd,
    lightMap: nbsidewalklm,
    lightMapIntensity: 7,
  });
  
  return (
    <group {...props} dispose={null}>

      <mesh geometry={nodes.Cube009.geometry} material={nbsidewalkmat} />
      <mesh geometry={nodes.Cube009_1.geometry} material={nbfrontstepmat} />
    </group>
  )
}
export const NBSidewalk = () => {
  return <NBSidewalkF></NBSidewalkF>;
};
useGLTF.preload('/glb/NBSidewalk.glb')
