import {
  DocumentData,
  Query,
  QueryConstraint,
  collection,
  doc,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import { UserAccountBrandPermission } from "../models/user-account-brand-permission";
import DataTransformationService from "./data-transformation.service";

function generateId(userAccountId: string, brandId: string) {
  return `${userAccountId}_${brandId}`;
}

function getOne(
  userAccountPermissionId: string
): Observable<UserAccountBrandPermission> {
  const docRef = doc(
    db,
    `userAccountBrandPermissions/${userAccountPermissionId}`
  );
  return docData(docRef).pipe(
    map((firestoreDocument) =>
      DataTransformationService.convertTimestampsToIsoString(firestoreDocument)
    )
  );
}

async function saveOne(
  userAccountBrandPermission: Partial<UserAccountBrandPermission>,
  isNew: boolean
): Promise<void> {
  try {
    if (!userAccountBrandPermission.id)
      throw new Error("Missing user account.");

    if (isNew) {
      userAccountBrandPermission.createdDate = serverTimestamp();
      userAccountBrandPermission.createdByUserAccountId = auth.currentUser?.uid;
    } else {
      userAccountBrandPermission.updatedDate = serverTimestamp();
      userAccountBrandPermission.updatedByUserAccountId = auth.currentUser?.uid;
    }

    if (userAccountBrandPermission.deleted == undefined)
      userAccountBrandPermission.deleted = false;

    let docRef = doc(
      db,
      `userAccountBrandPermissions/${userAccountBrandPermission.id}`
    );
    await setDoc(docRef, userAccountBrandPermission, { merge: false });
    return;
  } catch (err) {
    throw err;
  }
}

async function deleteOne(id: string): Promise<any> {
  try {
    let docRef = doc(db, `userAccountBrandPermissions/${id}`);
    const documentToDelete = {
      deleted: true,
      deletedDate: serverTimestamp(),
      updatedDate: serverTimestamp(),
      updatedByUserAccountId: auth.currentUser?.uid,
    };
    await setDoc(docRef, documentToDelete, { merge: true });
    return;
    return;
  } catch (err) {
    throw err;
  }
}

function getAllForBrand(
  tenantId: string,
  brandId: string
): Observable<UserAccountBrandPermission[]> {
  const collectionRef = collection(db, "userAccountBrandPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

function getAllForUserAccount(
  tenantId: string,
  userAccountId: string
): Observable<UserAccountBrandPermission[]> {
  const collectionRef = collection(db, "userAccountBrandPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("userAccountId", "==", userAccountId),
    where("deleted", "==", false),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

function getAllForUserAccountIncludingDeleted(
  tenantId: string,
  brandId: string,
  userAccountId: string
): Observable<UserAccountBrandPermission[]> {
  const collectionRef = collection(db, "userAccountBrandPermissions");

  const queryConstraint: QueryConstraint[] = [
    where("tenantId", "==", tenantId),
    where("brandId", "==", brandId),
    where("userAccountId", "==", userAccountId),
  ];

  const collectionQuery: Query<DocumentData> = query(
    collectionRef,
    ...queryConstraint
  );

  return collectionData(collectionQuery).pipe(
    map((collection) =>
      collection.map((firestoreDocument) =>
        DataTransformationService.convertTimestampsToIsoString(
          firestoreDocument
        )
      )
    )
  );
}

const UserAccountBrandPermissionService = {
  generateId,
  getOne,
  saveOne,
  deleteOne,
  getAllForBrand,
  getAllForUserAccount,
  getAllForUserAccountIncludingDeleted,
};

export default UserAccountBrandPermissionService;
