/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBProductFCSCE.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture, Float } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    NBProductFCSCE: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBProductFCSCEF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBProductFCSCE.glb') as GLTFResult

  const nbproductfcsced = useTexture(
    "/textures/NBProductFCSCED.webp"
  );
  nbproductfcsced.flipY = false;
  nbproductfcsced.colorSpace = THREE.SRGBColorSpace;
  

  const nbproductfcscemat = new THREE.MeshBasicMaterial({
    map: nbproductfcsced,
  });


  return (
    <group {...props} dispose={null}>
       <Float floatIntensity={0.05} speed={7.1} rotationIntensity={0.04} floatingRange={[ -0.1,0.1]}>
      <mesh geometry={nodes.NBProductFCSCE.geometry} material={nbproductfcscemat} position={[2.3, 0.985, -3.48]} rotation={[2.024, -0.025, 1.519]} />
      </Float>
      </group>
  )
}
export const NBProductFCSCE = () => {
  return (
    
    
    <NBProductFCSCEF></NBProductFCSCEF>
  );
};
useGLTF.preload('/glb/NBProductFCSCE.glb')
