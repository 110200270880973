/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBRearEnv.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF , useTexture} from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Plane001: THREE.Mesh
  }
  materials: {
    
  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBRearEnvF(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBRearEnv.glb') as GLTFResult
 
 
  const nbrearenvd = useTexture("/textures/NBRearEnvD.webp");
  nbrearenvd.flipY = false;
  nbrearenvd.colorSpace = THREE.SRGBColorSpace;
  
  const nbrearenvmat = new THREE.MeshBasicMaterial({
    map: nbrearenvd,

  });
  
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane001.geometry} material={nbrearenvmat} position={[1.239, 4, -24.764]} />
    </group>
  )
}
export const NBRearEnv = () => {
  return <NBRearEnvF></NBRearEnvF>;
};
useGLTF.preload('/glb/NBRearEnv.glb')
