import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";

import * as Sentry from "@sentry/react";
import { datadogRum } from "@datadog/browser-rum";

// Only enable monitoring on staging and production environments
if (process.env.NODE_ENV !== "development") {
  // Sentry Performance Monitoring
  Sentry.init({
    enabled: true,
    environment: document.location.hostname,
    dsn: "https://ca9266ae03360984870ca61fb078eb2d@o4506389845573632.ingest.us.sentry.io/4507255612833792",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // DataDog Real User Monitoring
  datadogRum.init({
    applicationId: "1ed838aa-19ea-4f4e-8a98-b3474adab5e8",
    clientToken: "pubba889cfcc556ade98e0398a3f3c1ba46",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "us5.datadoghq.com",
    service: "drawbridge",
    env: document.location.hostname,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
