import { createContext, useContext } from "react";

export type VideoContextType = {
  video: HTMLVideoElement;
  videoTexture: THREE.VideoTexture;
  hasPlayedHostVideo: boolean;
  hasExperienceLoaded: boolean;
};

export const VideoContext = createContext<VideoContextType>({
  video: null,
  videoTexture: null,
  hasPlayedHostVideo: false,
  hasExperienceLoaded: false,
});

export const useVideoContext = () => {
  const context = useContext(VideoContext);
  return context;
};
