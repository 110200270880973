interface SceneIndicatorProps {
  selectedSceneIndex: number;
  sceneCount: number;
}

export const SceneIndicator = ({
  selectedSceneIndex,
  sceneCount,
}: SceneIndicatorProps) => {
  return (
    <div className="flex flex-row gap-3 ml-11 mr-11">
      {Array.from({ length: sceneCount }).map((_, index) => (
        <div
          key={index}
          className={`h-1 w-full rounded-full -mt-3 ${
            selectedSceneIndex === index ? "bg-destructive" : "bg-white"
          }`}
        />
      ))}
    </div>
  );
};
