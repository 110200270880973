import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useExperienceContext } from "@/context/experience.context";
import { FC, useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import ProductInformation from "./product-information";
import { Card, CardContent } from "../ui/card";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";

const MenuDrawer: FC<{
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  // REFERENCE:
  const [snap, setSnap] = useState<number | string | null>(1);

  const { experience, realExperienceVirtualObjects, listProductVariants } =
    useExperienceContext();
  const [
    savedListOfExperienceVirtualObjects,
    setSavedListOfExperienceVirtualObjects,
  ] = useState<ExperienceVirtualObject[]>([]);

  useEffect(() => {
    const newListOfExperienceVirtualObjects: ExperienceVirtualObject[] = [];
    for (const listProductVariant of listProductVariants) {
      const matchingExperienceVirtualObjects =
        realExperienceVirtualObjects.find(
          (experienceVirtualObject) =>
            experienceVirtualObject.productId === listProductVariant.productId
        );
      if (matchingExperienceVirtualObjects) {
        newListOfExperienceVirtualObjects.push(
          matchingExperienceVirtualObjects
        );
      }
    }
    setSavedListOfExperienceVirtualObjects(newListOfExperienceVirtualObjects);
  }, [listProductVariants, realExperienceVirtualObjects]);

  return (
    <>
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        snapPoints={[1]}
        activeSnapPoint={snap}
        setActiveSnapPoint={setSnap}
      >
        <DrawerPortal>
          <DrawerContent className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[95%] mx-[-1px]">
            <div className="absolute -top-3 right-3 z-10">
              <Button
                size="icon"
                onClick={() => setIsOpen(false)}
                variant="default"
              >
                <HiOutlineX className="w-6 h-6" />
              </Button>
            </div>
            <DrawerHeader className="hidden">
              <DrawerTitle className="hidden">Menu</DrawerTitle>
              <DrawerDescription className="hidden">
                Menu for the experience
              </DrawerDescription>
            </DrawerHeader>
            <Tabs defaultValue="products" className="w-full pl-2 pr-2">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="products">All Products</TabsTrigger>
                <TabsTrigger value="saved">
                  Saved{" "}
                  {savedListOfExperienceVirtualObjects.length > 0 && (
                    <Badge variant="default" className="ml-1">
                      {savedListOfExperienceVirtualObjects.length}
                    </Badge>
                  )}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="saved">
                <ScrollArea className="h-screen">
                  {savedListOfExperienceVirtualObjects.length === 0 && (
                    <p>No products are in your saved list.</p>
                  )}
                  {savedListOfExperienceVirtualObjects.map(
                    (experienceVirtualObject) => (
                      <Card
                        key={experienceVirtualObject.id}
                        className="p-2 mb-4"
                      >
                        <ProductInformation
                          experienceVirtualObject={experienceVirtualObject}
                          allowProductRemoval={true}
                        />
                      </Card>
                    )
                  )}
                  <div className="h-40" />
                </ScrollArea>
              </TabsContent>
              <TabsContent value="products">
                <ScrollArea className="h-screen">
                  {realExperienceVirtualObjects.map(
                    (experienceVirtualObject) => (
                      <div key={experienceVirtualObject.id}>
                        {experienceVirtualObject.productId && (
                          <Card
                            key={experienceVirtualObject.id}
                            className="p-2 mb-4"
                          >
                            <ProductInformation
                              experienceVirtualObject={experienceVirtualObject}
                              allowProductRemoval={false}
                            />
                          </Card>
                        )}
                      </div>
                    )
                  )}
                  <div className="h-40" />
                </ScrollArea>
              </TabsContent>
            </Tabs>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
