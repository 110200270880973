/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBFF880Hero.glb -t -r public 
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useGLTF, useTexture, Float } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/Viewer/viewer/InteractionContext";
import { NBHeroShadowF } from "./NBHeroShadow";

type GLTFResult = GLTF & {
  nodes: {
    Cube007: THREE.Mesh;
    Cube007_1: THREE.Mesh;
    Cube007_2: THREE.Mesh;
  };
  materials: {
    ["NBMAster.001"]: THREE.MeshBasicMaterial;
    ["Material.002"]: THREE.MeshBasicMaterial;
    ["Material.003"]: THREE.MeshBasicMaterial;
  };
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBShoeF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBFF880Hero.glb") as GLTFResult;
  const { currentSceneId } = useInteractionContext();

  const meshRef1 = useRef<THREE.Mesh>(null);
  const meshRef2 = useRef<THREE.Mesh>(null);
  const meshRef3 = useRef<THREE.Mesh>(null);
  const meshRef4 = useRef<THREE.Mesh>(null);
  const meshRef5 = useRef<THREE.Mesh>(null);
  const meshRef6 = useRef<THREE.Mesh>(null);

  const [bounce, setBounce] = useState(false);

  const nb880bodyd = useTexture("/textures/NB880BodyD.webp");
  nb880bodyd.flipY = false;
  nb880bodyd.colorSpace = THREE.SRGBColorSpace;

  const nb880lacesd = useTexture("/textures/NB880LacesD.webp");
  nb880lacesd.flipY = false;
  nb880lacesd.colorSpace = THREE.SRGBColorSpace;
  nb880lacesd.wrapS = THREE.RepeatWrapping;
  nb880lacesd.wrapT = THREE.RepeatWrapping;

  const nb880tongued = useTexture("/textures/NB880TongueD.webp");
  nb880tongued.flipY = false;
  nb880tongued.colorSpace = THREE.SRGBColorSpace;

  const nb880xraytongue = useTexture("/textures/NB880XrayTongue.webp");
  nb880xraytongue.flipY = false;
  nb880xraytongue.colorSpace = THREE.SRGBColorSpace;

  const nb880xraytonguea = useTexture("/textures/NB880XrayTongueA.webp");
  nb880xraytonguea.flipY = false;
  nb880xraytonguea.colorSpace = THREE.SRGBColorSpace;

  const nb880xraybody = useTexture("/textures/NB880XrayBody.webp");
  nb880xraybody.flipY = false;
  nb880xraybody.colorSpace = THREE.SRGBColorSpace;

  const nb880xraybodya = useTexture("/textures/NB880XrayBodyA.webp");
  nb880xraybodya.flipY = false;
  nb880xraybodya.colorSpace = THREE.SRGBColorSpace;

  const nb880xraylaces = useTexture("/textures/NB880XrayLaces.webp");
  nb880xraylaces.flipY = false;
  nb880xraylaces.colorSpace = THREE.SRGBColorSpace;

  const nb880bodymat = new THREE.MeshStandardMaterial({
    map: nb880bodyd,
    transparent: true,
    side: THREE.DoubleSide,
    roughness: 0.9,
    opacity: 0,
  });

  const nb880tonguemat = new THREE.MeshStandardMaterial({
    map: nb880tongued,
    transparent: true,
    side: THREE.DoubleSide,
    roughness: 0.8,
    opacity: 0,
  });

  const nb880lacesmat = new THREE.MeshStandardMaterial({
    map: nb880lacesd,
    transparent: true,
    side: THREE.DoubleSide,
    roughness: 1,
    opacity: 0,
  });

  const nbbodyxraymat = new THREE.MeshBasicMaterial({
    map: nb880xraybody,
    transparent: true,
    opacity: 0.95,
    side: THREE.DoubleSide,
  });

  const nbtonguexraymat = new THREE.MeshBasicMaterial({
    map: nb880xraytongue,
    transparent: true,
    opacity: 0.95,
    side: THREE.DoubleSide,
  });

  const nblacesxraymat = new THREE.MeshBasicMaterial({
    map: nb880xraylaces,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.95,
  });

  // const nbshoexraymat = new THREE.MeshStandardMaterial({
  //   color: "#00ACFF",
  //   transparent: true,
  //   opacity: 0.7,
  //   emissive: "#00ACFF",
  //   side: THREE.DoubleSide,
  //   emissiveIntensity: 0.5,
  //   roughness: 0.5,
  //   wireframe: true,
  // });

  const [fadeProgress, setFadeProgress] = useState(0);

  useFrame((_, delta) => {
    if (meshRef1.current && meshRef4.current) {
      const targetFade = currentSceneId === "1" ? 0 : 1;
      setFadeProgress((prev) =>
        THREE.MathUtils.lerp(prev, targetFade, delta * 0.7)
      );

      meshRef1.current.rotation.y += 0.006;
      meshRef2.current.rotation.y += 0.006;
      meshRef3.current.rotation.y += 0.006;
      meshRef4.current.rotation.y += 0.006;
      meshRef5.current.rotation.y += 0.006;
      meshRef6.current.rotation.y += 0.006;

      if (bounce) {
        meshRef1.current.rotation.y += 0.07;
        meshRef2.current.rotation.y += 0.07;
        meshRef3.current.rotation.y += 0.07;
        meshRef4.current.rotation.y += 0.07;
        meshRef5.current.rotation.y += 0.07;
        meshRef6.current.rotation.y += 0.07;
      }

      const material1 = meshRef1.current.material as THREE.MeshStandardMaterial;
      const material2 = meshRef2.current.material as THREE.MeshStandardMaterial;
      const material3 = meshRef3.current.material as THREE.MeshStandardMaterial;
      const material4 = meshRef4.current.material as THREE.MeshBasicMaterial;
      const material5 = meshRef5.current.material as THREE.MeshBasicMaterial;
      const material6 = meshRef6.current.material as THREE.MeshBasicMaterial;

      material4.opacity = 0.95 * (1 - fadeProgress);
      material5.opacity = 0.95 * (1 - fadeProgress);
      material6.opacity = 0.95 * (1 - fadeProgress);
      material1.opacity = fadeProgress;
      material2.opacity = fadeProgress;
      material3.opacity = fadeProgress;
    }
  });

  const handleClick = () => {
    setBounce(true);
    setTimeout(() => {
      setBounce(false);
    }, 100);
  };

  return (
    <group {...props} dispose={null}>
      <NBHeroShadowF bounce={bounce} />
      <Float
        floatIntensity={1.5}
        speed={3}
        rotationIntensity={0.2}
        floatingRange={[-0.1, 0.2]}
      >
        <mesh
          ref={meshRef1}
          geometry={nodes.Cube007.geometry}
          material={nb880bodymat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
        <mesh
          ref={meshRef2}
          geometry={nodes.Cube007_1.geometry}
          material={nb880tonguemat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
        <mesh
          ref={meshRef3}
          geometry={nodes.Cube007_2.geometry}
          material={nb880lacesmat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
        <mesh
          ref={meshRef4}
          geometry={nodes.Cube007.geometry}
          material={nbbodyxraymat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
        <mesh
          ref={meshRef5}
          geometry={nodes.Cube007_1.geometry}
          material={nbtonguexraymat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
        <mesh
          ref={meshRef6}
          geometry={nodes.Cube007_2.geometry}
          material={nblacesxraymat}
          position={[-0.177, 0.832, 1.183]}
          rotation={[0, 0.8, 0]}
          onClick={handleClick}
        />
      </Float>
    </group>
  );
}
export const NBShoe = () => {
  return <NBShoeF />;
};
useGLTF.preload("/glb/NBFF880Hero.glb");
