import { PositionalAudio } from "@react-three/drei";

export const NBAmbientAudio = () => {
    return (
<PositionalAudio
  url="/audio/NBAmbient.mp3"
  autoplay
  distance={50}
  loop
/>
    );
  };
  