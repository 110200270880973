/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBFrontEnv.glb -t -r public 
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF, useTexture} from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Plane: THREE.Mesh
  }
  materials: {

  }
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

export function NBFrontEnvF (props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/glb/NBFrontEnv.glb') as GLTFResult

   
  const nbfrontenvd = useTexture("/textures/NBFrontEnvD.webp");
  nbfrontenvd.flipY = false;
  nbfrontenvd.colorSpace = THREE.SRGBColorSpace;

  
  const nbfrontenvmat = new THREE.MeshBasicMaterial({
    map: nbfrontenvd,

  });


  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Plane.geometry} material={nbfrontenvmat} position={[-0.529, -0.22, 9.752]} />
    </group>
  )
}
export const NBFrontEnv = () => {
  return <NBFrontEnvF></NBFrontEnvF>;
};
useGLTF.preload('/glb/NBFrontEnv.glb')
