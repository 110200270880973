import { Scene } from "@/models/scene";
import { demoHouseScenes } from "./demo-house/scenes";
import { nbScenes } from "./WWM-NB/scenes";
import { lazy } from "react";
import { DemoHouseVirtualObjectModelMapping } from "./demo-house/shared/DemoHouseModelMapping";
import { NBVirtualObjectModelMapping } from "./WWM-NB/shared/NBModelMapping";

/**
 * SetComponentDictionary is a dictionary object that maps string keys to lazy-loaded 3D set component imports.
 * The keys represent the names of the sets, and the values are the corresponding lazy-loaded component imports.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */
export const SetComponentDictionary: Record<string, any> = {
  DemoHouse: lazy(() => import("./demo-house/index")),
  WWMNewBalance: lazy(() => import("./WWM-NB/index")),
};

/**
 * Represents a dictionary of scenes.
 * The keys represent the names of the sets, and the values are the corresponding Scenes array for the set.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */
export const ScenesDictionary: Record<string, Scene[]> = {
  DemoHouse: demoHouseScenes,
  WWMNewBalance: nbScenes,
};

/**
 * Represents a dictionary mapping virtual object names to their corresponding JSX elements.
 * The keys represent the names of the sets, and the values are the corresponding Virtual Objects Model Mapping for the set.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */
export const SetVirtualObjectMapping: Record<
  string,
  Record<string, JSX.Element>
> = {
  // DemoHouse: DemoHouseVirtualObjectModelMapping, //TODO: THIS HAS TO BE LAZY LOADED IN THE FUTURE SOME HOW
  WWMNewBalance: NBVirtualObjectModelMapping,
};
